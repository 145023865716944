import React from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { StringParam, useQueryParams } from 'use-query-params';
import { SongSortBy, SortDirection, useSongsQuery } from '../generated/graphql';
import { LibraryContext } from '../library/LibraryContext';
import { BaseProps, SongFilter } from './SongsListGroup';




// const songMatchesKeyword = (s: SongsFragment, kw: string) =>
//   iContains(s.title, kw) ||
//   // iContains(s.chordChart, kw) ||
//   iContains(s.author, kw)
//   // iContains(s.notes, kw) ||
//   // iContains(s.copyright, kw) ||
//   // iExact(s.key, kw) ||
//   // iExact(s.year, kw);


// export const filterSongs = (items: ReadonlyArray<SongsFragment>, filter: SongFilter) => 
//   filterByKeywords(filter.q, items, songMatchesKeyword);


// export const FilterCount = (props: { filtered: number; total: number, entityName: string }) =>
// <>
//   {props.total === props.filtered
//     ? <>Showing all <b>{props.total}</b> {props.entityName}{props.total === 1 ? "" : "s"}</>
//     : <>Found <b>{props.filtered}</b> match{props.filtered === 1 ? "" : "es"}</>
//   }
// </>


interface Config extends BaseProps {
  pause?: boolean
}

export const useSongList = (config: Config = {}) => {
  const lib = React.useContext(LibraryContext)

  // Filter State is stored in the URL
  const [filter, setFilter] = useQueryParams({ 
    q: StringParam, 
    sortBy: StringParam, 
    sortDir: StringParam,
  })
  
  // Debounced callback so that we only query every 250ms
  const setFilterDebounced = useDebouncedCallback((value) => setFilter(value), 200);

  const [result] = useSongsQuery({
    variables: {
      filter: {
        libraryId: lib.id,
        q: filter.q || null,
      },
      sort: filter.sortBy && filter.sortDir 
        ? {by: filter.sortBy as SongSortBy, direction: filter.sortDir as SortDirection}
        : null
    },
    requestPolicy: "cache-and-network",
    pause: config.pause
  });
  
  return {
    ...result,
    filter: ({
      q: filter.q || '',
      ...(
        filter.sortBy && filter.sortDir ?
        {sortBy: filter.sortBy, sortDir: filter.sortDir}
        : {sortBy: SongSortBy.Title, sortDir: SortDirection.Asc}
      ),
    }) as SongFilter,
    setFilter,
    setFilterDebounced,
    filterQuery: filter.q || filter.sortDir || filter.sortBy 
      ? "?" + [filter.q && `q=${encodeURIComponent(filter.q || '')}`, filter.sortBy && `sortBy=${filter.sortBy}`, filter.sortDir && `sortDir=${filter.sortDir}`].filter(x=>x).join('&') : '',
  }
};
