import React from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { ShowModalButton } from '../chrome/ShowModalButton';
import { CompactHookFormInput } from '../forms/CompactHookFormInput';
import { useCreateLibraryMutation } from '../generated/graphql';
import { buildLink } from '../routes';
import { useMutationFormHelpers } from '../songform/hooks';


interface Props {
  children: React.ReactNode
  className: string
}


export const AddLibraryButton = (props: Props) => {
  const defaultValues = {title: ''}
  const [executeResult, executeCreate] = useCreateLibraryMutation();
  const { register, handleSubmit, setError, formState: {errors}, reset } = useForm({ defaultValues: defaultValues });
  const formHelpers = useMutationFormHelpers({defaultValues, setError});
  const history = useHistory();
  return (
    <ShowModalButton
      name='CreateNewLibrary'
      button={{
        className: props.className,
        children: props.children,
      }}
      modal={{
        title: "Create new library",
        scrollable: true,
        footer: <>
          <button type="button" className="ms-auto btn btn-light" data-bs-dismiss="modal">Cancel</button>
          <input type="submit" className="ms-3 btn btn-dark" value={executeResult.fetching ? 'Creating...' : "Create library"}/>
        </>,
      }}
      onSubmit={async (e, modal) => 
        handleSubmit(async data => {
          
          formHelpers.clearMutationError()
          const r = await executeCreate({data});
          if (r.data?.createLibrary?.__typename === 'CreateLibrarySuccess') {
            await modal.hide();
            history.push(buildLink.settings(r.data.createLibrary.library.id));
            reset();
          }
          else {
            formHelpers.processMutationError(r, r.data?.createLibrary)
          }
          
        })(e)
        
      }
    >
      <CompactHookFormInput label="Library title"  error={errors.title}
        inputProps={register('title', {required: 'Required', maxLength: {value: 100, message: 'Maximum of 100 characters'}})}
      />
      {formHelpers.mutationError && <div className="alert alert-danger" role="alert">{formHelpers.mutationError}</div>}
    </ShowModalButton>
  );
};
