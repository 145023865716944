import React from 'react';
import cs from 'classnames';
import { Link } from 'react-router-dom';


export const actionButtonClassName = 'btn btn-link';


export const ActionButton = ({className, ...passThruProps}: React.HTMLAttributes<HTMLButtonElement>) => (
  <button className={cs(className, actionButtonClassName)} {...passThruProps}/>
);


export const ActionLink = ({className, ...passThruProps}: React.ComponentProps<Link>) => (
  <Link className={cs(className, actionButtonClassName)} {...passThruProps}/>
);

