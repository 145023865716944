import React from 'react';
import { useHistory } from 'react-router-dom';
import { ShowModalButton } from '../chrome/ShowModalButton';
import { FirstDayOfWeek, useCreateSetlistMutation } from '../generated/graphql';
import { LibraryContext } from '../library/LibraryContext';
import { useSetlistForm } from '../setlist/useSetlistForm';


interface Props {
  children: React.ReactNode
  className: string
  userDateFormat: string
  firstDayOfWeek: FirstDayOfWeek
}


export const SetlistsAddButton = (props: Props) => {
  const lib = React.useContext(LibraryContext);
  const defaultValues = {
    title: '',
    date: '',
    notes: '',
    leader: '',
  }
  const [, createSetlist] = useCreateSetlistMutation();
  const {formHelpers, handleSubmit, ...form} = useSetlistForm({
    defaultValues,
    userDateFormat: props.userDateFormat,
    firstDayOfWeek: props.firstDayOfWeek,
  })
  const history = useHistory();

  return (
    <ShowModalButton
      name="SetlistsAddButton"
      button={{
        className: props.className,
        children: props.children,
      }}
      modal={{
        title: "Create new setlist",
        footer: <input type="submit" className="ms-auto btn btn-dark" value="Create setlist"/>
      }}
      onSubmit={(event, modal) => handleSubmit(async data => {

          formHelpers.clearMutationError()
          const r = await createSetlist({
            libraryId: lib.id, 
            data: { draft: true, ...form.prepareSubmitData(data) }
          });
          if (r.data?.createSetlist?.__typename === "CreateSetlistSuccess") {
            await modal.hide();
            history.push(lib.buildLink.setlist(r.data.createSetlist.setlist.id));
          }
          else {
            formHelpers.processMutationError(r, r.data?.createSetlist)
          }
          
        })(event)
      }
    >

      <form.formFields/>
      {formHelpers.mutationError && <div className="alert alert-danger" role="alert">{formHelpers.mutationError}</div>}

    </ShowModalButton>
  );
};