import React from 'react';
import { useForm } from 'react-hook-form';
import { ShowModalButton } from '../chrome/ShowModalButton';
import { CompactHookFormInput } from '../forms/CompactHookFormInput';
import { CompactHookFormSelect } from '../forms/CompactHookFormSelect';
import { CompactHookFormTextarea } from '../forms/CompactHookFormTextarea';
import { LibraryAccess, useCreateMemberInviteMutation } from '../generated/graphql';
import { useMutationFormHelpers } from '../songform/hooks';
import { prettyAccess } from './access';


interface Props extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>{
  // onSuccess: (song: SongFragment) => void;
  libraryId: number
}


export const InviteMemberButton = (props: Props) => {
  const [mutationStatus, execute] = useCreateMemberInviteMutation();
  const {onClick, children, libraryId, ...passThru} = props;
  const defaultValues = {
    toEmail: '',
    access: '' as LibraryAccess,
    message: '',
  };
  const { register, handleSubmit, setError, formState: {errors} } = useForm({ defaultValues });
  const formHelpers = useMutationFormHelpers({defaultValues: defaultValues, setError});

  return (
    <ShowModalButton
      name="InviteMemberButton"
      button={{...passThru, children: props.children}}
      modal={{

        title: "Invite somebody to this libary" ,
        footer: 
          <>
            <button className='btn btn-default' data-bs-dismiss='modal' type='button'>
              Cancel
            </button>
            <button type="submit" disabled={mutationStatus.fetching} className="btn btn-dark">Send Invite</button>
          </>
  
        
      }}
      onSubmit={(event, modal) => handleSubmit(async data => {
        
        formHelpers.clearMutationError()
        const r = await execute({  data: {...data, libraryId: props.libraryId } });
        if (r.data?.createMemberInvite.__typename === 'CreateMemberInviteSuccess') {
          await modal.hide();
        }
        else {
          formHelpers.processMutationError(r, r.data?.createMemberInvite)
        }

      })(event)}
    >

      <CompactHookFormInput label="To email"  error={errors.toEmail}
        inputProps={register('toEmail', {required: 'Required'})}
      />

      <CompactHookFormSelect label="Access level"  error={errors.access}
        inputProps={register('access', {required: 'Required'})}
      >
        {[LibraryAccess.FullControl, LibraryAccess.ReadOnly].map(a => <option value={a}>{prettyAccess(a)}</option>)}
      </CompactHookFormSelect>


      <CompactHookFormTextarea label="Message"  error={errors.message}
        inputProps={{...register('message'), rows: 4}}
      />

      {formHelpers.mutationError && <div className="alert alert-danger" role="alert">{formHelpers.mutationError}</div>}

    </ShowModalButton>
  );
};
