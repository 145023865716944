import React, { ReactComponentElement } from 'react';
import { useElementId } from '../hooks/elementIds';
import styles from './CompactFormField.module.scss';
import cs from 'classnames';


interface Props {
  id: string
  label: React.ReactNode
  children: React.ReactNode
  help?: React.ReactNode
  error?: React.ReactNode
}


export const CompactFormField = ({id, ...props}: Props) => {
  
  return (
    <div className={cs("mb-3", styles.FormField)}>
      {props.children}
      <label htmlFor={id + '-field'} className="form-label">{props.label}</label>
      <div id={id + '-help'} className="form-text">{props.help}</div>
      <div id={id + '-feedback'} className="invalid-feedback">{props.error}</div>
    </div>
  )
};
