import cs from 'classnames';
import dates, { DateObj } from '../dates';


interface Props {
  date: DateObj
  value: DateObj | null
  onClick?: (day: DateObj) => void
  muted?: boolean
}


export const CalendarDayButton = ({date, ...props}: Props) => (
  <button 
    className={cs(
      'btn p-1 rounded-0', 
      dates.isToday(date) && 'border border-dark',
      props.value?.isSame(date, 'day') ? 'btn-dark' : 'btn-light',
      props.muted && 'opacity-25'
    )} 
    onClick={() => props.onClick && props.onClick(date)}
    type='button'
  >
    {date.date()}
  </button>
);
