import React, { FormEvent } from 'react';
import { Modal, ModalProps, UseModal, useModal } from '../chrome/Modal';


interface Props {
  name?: string
  title?: React.ReactNode
  button: Omit<React.HTMLProps<HTMLButtonElement>, 'type'>
  modal: Omit<ModalProps, 'ref' | 'id' | 'children'>
  buttonClassName?: string
  children: React.ReactNode
  onSubmit?: (event: FormEvent<HTMLFormElement>, modal: UseModal) => void  // EventHandler<FormEvent<T>>
  onClose?: () => void
}


export const ShowModalButton = (props: Props) => {
  const modal = useModal({name: props.name});
  const {onSubmit, ...modalPassThru} = props.modal;

  // Track visibility so we can call onClose()
  const [wasVisible, setWasVisible] = React.useState(false);
  React.useEffect(() => {
    if (wasVisible && !modal.visible) {
      if (props.onClose) {
        props.onClose();
      }
      setWasVisible(false)
    }
    else if (!wasVisible && modal.visible) {
      setWasVisible(true)
    }
  }, [modal.visible])
  return (
    <>
      <button 
          type='button'
          data-bs-toggle="modal" 
          data-bs-target={`#${modal.htmlId}`} 
          {...props.button}
      />
      <Modal ref={modal.ref} id={modal.htmlId} {...modalPassThru} onSubmit={e => {
        if (props.onSubmit) {
          props.onSubmit(e, modal);
        }
      }}>
        {modal.visible && props.children}
      </Modal>
    </>
  );
};
