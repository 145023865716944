import { faThumbTack } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cs from 'classnames';
import React, { ReactNode } from 'react';
import ReactDOM from 'react-dom';
import { useElementId } from '../hooks/elementIds';
import { ModalContainer } from './Modal';


interface Props {
  id: string
  children: React.ReactNode
  footer?: React.ReactNode
  title?: React.ReactNode
  size?: 'sm' | 'lg' | 'xl'
  scrollable?: boolean
  flush?: boolean
  headerClassName?: string
  afterHeader?: React.ReactNode
  placement?: 'start' | 'end' | 'top' | 'bottom'
  onPin?: () => void
}

export const OffCanvas = React.forwardRef<HTMLDivElement, Props>((props, ref) => (
  <ModalContainer>
    <div ref={ref} className={`offcanvas offcanvas-${props.placement ?? 'start'}`} id={props.id} tabIndex={-1} aria-labelledby={`${props.id}Label`} aria-hidden="true">
      <div className={cs("offcanvas-header", props.onPin && 'justify-content-start', props.headerClassName)}>
        {props.title && <h5 className="offcanvas-title" id={`${props.id}Label`}>{props.title}</h5>}
        {props.onPin && 
          <button 
              type="button" 
              className={cs("btn-close ms-auto me-2")}
              style={{backgroundImage: 'none', lineHeight: '1'}}
              data-bs-dismiss="offcanvas" aria-label="Pin to side"
              onClick={props.onPin}
            >
            <FontAwesomeIcon icon={faThumbTack} className='mt-n2'/>
          </button>
        }
        <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"/>
      </div>
      {props.afterHeader}
      <div className={cs("offcanvas-body", props.flush && 'p-0')}>
        {props.children}
      </div>
    </div>
  </ModalContainer>
));
