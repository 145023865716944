import { faGripDotsVertical } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useMedia } from 'react-media';
import { Link, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { BooleanParam, NumberParam, StringParam, useQueryParams } from 'use-query-params';
import { AccountSettingsCard } from '../account/AccountSettingsCard';
import { AppContext } from '../app/AppContext';
import { FullPage, FullPageContext } from '../chrome/FullPage';
import { H1 } from '../chrome/H1';
import { Loading } from '../chrome/Loading';
import dates from '../dates';
import { SetlistSongFragment, useDeleteSetlistMutation, useRecordSongTransposeMutation, useSetlistQuery } from '../generated/graphql';
import { LibraryContext } from '../library/LibraryContext';
import { GRID_MEDIA_QUERIES } from '../media';
import { routes } from '../routes';
import { DisplayKey } from '../transpose/KeySelectHooks';
import { getListInfo } from '../utils/listInfo';
import { SetlistAddSong } from './SetlistAddSong';
import { SetlistForm } from './SetlistForm';
// import { SetlistForm } from '../setlists/SetlistsAddButton';
import { SetlistMeta } from './SetlistMeta';
import { SetlistSongActions } from './SetlistSongActions';
import { SetlistSongPane } from './SetlistSongPane';
import { SetlistSongs } from './SetlistSongs';
import { SetlistSongTable } from './SetlistSongTable';


/*
Layout for setlist song is

______________________________________________________________________
| <- Setlist name    | Song Name & Author       |     Action buttons |
|                    | _________________________|____________________|
|    About           | 
|                    | Chord Chart
| 1. First Song   D  |
| 2. Second Song  E  |
| 3. Third Song   A  |
| 4. Fourth Song  G  |
|                    |
| +  Add Song        |
|____________________|_______________________________________________   
*/

interface Props {
  id: number
  className?: string
  onDelete?: () => void
  backTo?: string
}


export const SetlistPage = (props: Props) => {
  const app = React.useContext(AppContext);
  const lib = React.useContext(LibraryContext);
  // const u = useRouteParams()
  const u = useRouteMatch<{setlistsong: string}>({path: routes.setlistSong})
  const history = useHistory()



  const [result] = useSetlistQuery({variables: {setlistId: props.id}});
  const listInfo = getListInfo(
    result.data?.setlist?.songs || [], 
    s => s.id === parseInt(u?.params.setlistsong || '')
  );
  
  const setlist = result.data?.setlist;
  
  // Using query for state so that reloads, etc work great
  const [queryParams, setQueryParams] = useQueryParams({
    add: BooleanParam,
    edit: BooleanParam,
    // song: NumberParam,
    semitones: NumberParam,
    style: StringParam,
  });
  
  // const toggleEdit = (value?: boolean) => setQueryParams({...queryParams, edit: value !== undefined ? value : !queryParams.edit})
  // const toggleAdd = (value?: boolean) => setQueryParams({...queryParams, add: value !== undefined ? value : !queryParams.add})
  
  // Expanded / Sidebar visibility
  const { sidebar } = React.useContext(FullPageContext);
  // const [expanded, setExpanded] = React.useState(app.setlistExpanded)
  // const toggleSidebar = () => {
  //   app.toggleSetlistExpanded(!expanded);
  //   setExpanded(!expanded);
  // };

  // Delete setlist
  const [, deleteSetlist] = useDeleteSetlistMutation();
  const handleDelete = async () => {
    if (result.data?.setlist?.libraryId){
      await deleteSetlist({id: props.id});
      history.push(lib.buildLink.library())
      if (props.onDelete) {
        props.onDelete()
      }
    }
  }

  const media = useMedia({queries: GRID_MEDIA_QUERIES})
  // const sidebarVisible = media.lgGTE && sidebar.visible;

  // const SetlistPane = ({collapseBtn}: {collapseBtn?: boolean}) => (
  //   <>
  //     <div className="d-flex align-items-center">
  //       {/* <Link to={lib.buildLink.setlists()} className="btn btn-link rounded-0" title="Back to setlists">
  //         <FontAwesomeIcon icon={icons.back}/>
  //       </Link>
  //       <H1 className="py-3">{setlist?.title || 'Setlist'}</H1> */}
  //       {/* <button className="btn btn-link rounded-0 px-3" title="Menu">
  //         <FontAwesomeIcon icon={icons.more}/>
  //       </button> */}

  //       {/* {collapseBtn && 
  //         <button className="btn btn-link rounded-0" title="Back to setlists" onClick={toggleSidebar}>
  //           <FontAwesomeIcon icon={faAngleLeft} className="me-1"/>
  //           <FontAwesomeIcon icon={faBars}/>
  //         </button>
  //       } */}
  //     </div>

  //     {setlist && 
  //       <>
  //         {queryParams.edit ? 
  //           <SetlistForm setlist={setlist}/>
  //         :  
  //           <SetlistMeta setlist={setlist}/>
  //         }
  //         <SetlistSongs songs={setlist.songs} add={!lib.readOnly} setlistId={props.id} selectedId={parseInt(u?.params.setlistsong || '0')} />
  //       </>
  //     }

  //   </>
  // );


  const [recordTransposeResult, executeRecordTranspose] = useRecordSongTransposeMutation();

  const setlistSongPageProps = {
    setlistId: props.id,
    onKeyChange: (t: DisplayKey) => {
      setQueryParams({...queryParams, semitones: t.semitones, style: t.style});
      if (listInfo.selected) {
        executeRecordTranspose({
          data: {
            songId: listInfo.selected.item.song.id, 
            setlistSongId: listInfo.selected.item.id, 
            transpose: {
              semitones: t.semitones, 
              style: t.style
            },
          }
        });
      }
    },
    sizeSource: sidebar.visible,
    onSidebarToggle: sidebar.toggle,
    sidebarToggle: false,
    back: !media.lgGTE,
  }

  // const SwipableSongPane = () => (
  //   <div className="h-100XX" style={{overflowX: 'hidden'}}>
  //     <SwipePanes 
  //       className="swipable-songs h-100XX"
  //       preventDefaultTouchmoveEvent={true}
  //       // trackMouse={true}
  //       prev={
  //         listInfo.prevItem ? 
  //           <SetlistSongPane 
  //             setlistSong={listInfo.prevItem} 
  //             listInfo={getListInfo(result.data?.setlist?.songs || [], s => s.id === listInfo.prevItem?.id)} 
  //             {...setlistSongPageProps}
  //           /> 
  //         : 
  //           null
  //       }
  //       next={
  //         listInfo.nextItem ? 
  //           <SetlistSongPane 
  //             setlistSong={listInfo.nextItem} 
  //             listInfo={getListInfo(result.data?.setlist?.songs || [], s => s.id === listInfo.nextItem?.id)} 
  //             {...setlistSongPageProps}
  //           /> 
  //         : 
  //           null
  //       }
  //       onChanged={e => {
  //         const newItem = (e.dir === LEFT && listInfo.nextItem) || (e.dir === RIGHT && listInfo.prevItem) || null
  //         if (newItem) {
  //           history.replace(lib.buildLink.setlistsong(props.id, newItem.id))
  //         }
  //       }}
  //     >
  //       {listInfo.selectedItem ? 
  //         <SetlistSongPane
  //           {...setlistSongPageProps}
  //           setlistId={props.id}
  //           listInfo={listInfo}
  //           setlistSong={listInfo.selectedItem}
  //         /> 
  //         : <div>Loading</div>} 
  //     </SwipePanes>
  //   </div>
  // )

  // const Content = () => (
  //   <Switch>
  //     <Route path={routes.setlistSong} component={SwipableSongPane}/>
  //     <Route path={routes.setlistAddSong}>
  //       <div className="flex-fill text-center">
  //         <br/><br/>
  //         <SetlistAddSong setlistId={props.id}/>
  //       </div>
  //     </Route>
  //     <Route>
  //       <div className="flex-fill text-center">
  //         <br/><br/>
  //         Setlist overview
  //       </div>
  //     </Route>
  //   </Switch>
  // )
  
  const sidebarInfo = {
    title: setlist?.title || 'New setlist',
    children: (
    
      <>
        {/* <div className="d-flex align-items-center"> */}
          {/* <Link to={lib.buildLink.setlists()} className="btn btn-link rounded-0" title="Back to setlists">
            <FontAwesomeIcon icon={icons.back}/>
          </Link>
          <H1 className="py-3">{setlist?.title || 'Setlist'}</H1> */}
          {/* <button className="btn btn-link rounded-0 px-3" title="Menu">
            <FontAwesomeIcon icon={icons.more}/>
          </button> */}

          {/* {collapseBtn && 
            <button className="btn btn-link rounded-0" title="Back to setlists" onClick={toggleSidebar}>
              <FontAwesomeIcon icon={faAngleLeft} className="me-1"/>
              <FontAwesomeIcon icon={faBars}/>
            </button>
          } */}
        {/* </div> */}

        {setlist && 
          <>
            {queryParams.edit ? 
              <div>Setlist form here</div>
              // <SetlistForm setlist={setlist}/>
            :  
              <SetlistMeta setlist={setlist}/>
            }
            <SetlistSongs songs={setlist.songs} add={!lib.readOnly} setlistId={props.id} selectedId={parseInt(u?.params.setlistsong || '0')} />
          </>
        }

      </>
    ),
    back: {to: lib.buildLink.setlists()}
  }

  const getPaneInfo = (setlistsong: SetlistSongFragment) => ({
    title: (
      <H1>
        <span className="me-2">{(listInfo.selected?.index|| 0) + 1}. {setlistsong.title}</span>
        {/* {song.author && <small className="lead text-muted">{song.author}</small>} */}
      </H1>
    ),
    children: (
      <SetlistSongPane
        {...setlistSongPageProps}
        setlistId={props.id}
        listInfo={listInfo}
        setlistSong={setlistsong}
      />),
    actions: (
      <SetlistSongActions 
        setlistSong={setlistsong} 
        listInfo={getListInfo(result.data?.setlist?.songs || [], s => s.id === listInfo.selected?.item.id)} 
        // listInfo={listInfo} 
        {...setlistSongPageProps}
        
      />
    )
  });

  const [editing, setEditing] = React.useState(false)
  const [ordering, setOrdering] = React.useState(false)

  const lgGte = useMedia({query: GRID_MEDIA_QUERIES.lgGTE});

  return (
    <Switch>

      <Route path={routes.setlist} exact>
        {p => (
          <FullPage
            title={<H1>{setlist?.title || 'Setlist'} {setlist?.isDraft && <small className="badge badge-warning ms-3">Draft</small>}</H1>}
            sidebar={sidebarInfo}
            back={{to: lib.buildLink.setlists()}}
          >
            <div className='row'>

              <div className='col-lg-4'>
              
                {setlist && !editing &&
                  <AccountSettingsCard title="About setlist" autoHeight onEdit={() => setEditing(true)}>

                    <dl className='mb-0'>
                      <dt>Title</dt>
                      <dd>{setlist.title}</dd>
                      <dt>Date</dt>
                      <dd>{dates.formatAsUser(setlist.date, app.me)}</dd>
                      <dt>Leader</dt>
                      <dd>{setlist.leader?.fullName && <i className='text-muted'>Not set</i>}</dd>
                      <dt>Notes</dt>
                      <dd>{setlist.notes || <i className='text-muted'>Not set</i>}</dd>
                    </dl>
                    {/* <LibraryForm 
                      library={library}
                      onSuccess={() => setState('home')}
                      onCancel={() => setState('home')}
                    />  */}
                  </AccountSettingsCard>
                }
                {setlist && editing && app.me &&
                  <AccountSettingsCard title="Setlist details" autoHeight>
                    <SetlistForm 
                      setlist={setlist} 
                      userDateFormat={app.me.preferences.dateFormat}
                      firstDayOfWeek={app.me.preferences.firstDayOfWeek}
                      onSuccess={() => setEditing(false)}
                    />
                  </AccountSettingsCard>
                }
              </div>
              <div className='col-lg-8'>
                {setlist && lgGte &&
                  <SetlistSongTable libraryId={lib.id} setlistId={setlist.id} setlist_songs={setlist.songs}/>
                }

                {!lgGte && 
                  <AccountSettingsCard title="Songs" flush action={
                    <>
                      <button className='btn btn-link' onClick={() => setOrdering(!ordering)}>{ordering ? 'Re-order songs' : 'Save song order' }</button>
                      <button className='btn btn-link'>Add songs</button>
                    </>
                  }>
                    {setlist && 
                      <div className="list-group list-group-flush">
                        {setlist.songs.map((s, index) => (
                          <Link key={s.id} className="list-group-item list-group-item-action " to={lib.buildLink.setlistsong(props.id, s.id)}>
                            <div className="d-flex ">
                              <div className="" style={{minWidth: '30px'}}><FontAwesomeIcon icon={faGripDotsVertical}/></div>
                              <div className="" style={{minWidth: '30px'}}>{index + 1}.</div>
                              <div className="flex-grow-1">
                                <div className="d-flex">
                                  <div className="me-auto">{s.title}</div>
                                  <b>
                                    {s.transpose.toKey || 
                                      <>
                                        <span title="Original key">Orig. </span>
                                        {(s.transpose.semitones || 0) > 0 && `+ ${s.transpose.semitones}`}
                                        {(s.transpose.semitones || 0) < 0 && s.transpose.semitones}
                                      </>
                                    }
                                  </b>
                                </div>

                                {s.notes && <small>{s.notes}</small>}
                              </div>
                              {/* <div className="d-flex w-100 justify-content-between"> */}
                                
                              {/* </div> */}
                              {/* <p className="mb-1">Some placeholder content in a paragraph.</p> */}
                            </div>
                          </Link>
                        ))}
                      </div>
                    }

                  </AccountSettingsCard>
                }
              </div>
            </div>

            <br/>
            <br/>


          </FullPage>
        )}
      </Route>

      <Route path={routes.setlistSong}>
        {p => listInfo.selected ? (
          <FullPage
            sidebar={{...sidebarInfo, back: {to: lib.buildLink.setlists()}}}
            back={{to: lib.buildLink.setlist(props.id)}}
            {...getPaneInfo(listInfo.selected.item)}
            prev={listInfo.previous ? getPaneInfo(listInfo.previous.item): undefined}
            next={listInfo.next ? getPaneInfo(listInfo.next.item): undefined}
          />
        ): 
          <Loading/>
        }
      </Route>
      
      <Route path={routes.setlistAddSong}>
        <div className="flex-fill text-center">
          <br/><br/>
          <SetlistAddSong setlistId={props.id}/>
        </div>
      </Route>

      <Route>
        <div className="flex-fill text-center">
          <br/><br/>
          Default setlist
        </div>
      </Route>
    </Switch>
  )

}