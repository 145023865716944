import React from 'react';
import { ShowModalButton } from '../chrome/ShowModalButton';


interface Props extends Omit<React.HTMLProps<HTMLButtonElement>, 'onClick' | 'type' | 'data-bs-toggle' | 'data-bs-target' | 'title'> {
  modal: {
    name: string
    title: React.ReactNode
    confirmText: React.ReactNode
    confirmDisabled?: boolean
    confirmClassName?: string
    body: React.ReactNode
    onConfirming?: () => Promise<string | void>
    onConfirmed?: () => void
  }
}


export const ModalConfirmButton = (props: Props) => {
  const {modal, ...btnPassThru} = props;
  const [error, setError] = React.useState<null | string>(null);
  const [confirming, setConfirming] = React.useState(false);
  const buttonRow = <>
    <button type="button" className="ms-auto btn btn-light" data-bs-dismiss="modal">Cancel</button>
    <button type="submit" className={props.modal.confirmClassName ?? "ms-3 btn btn-dark"} disabled={confirming}>
      {props.modal.confirmText}
    </button>
  </>
  return (
    <>
      <ShowModalButton
        name={props.modal.name}
        button={{
          ...btnPassThru
        }}
        modal={{
          title: props.modal.title,
          scrollable: true,
          footer: 
            error ? (
              <div className='w-100'>
                <div className='text-danger text-left mb-3'>{error}</div>
                <div className='d-flex'>{buttonRow}</div>
              </div>
            ) : buttonRow,
        }}
        onSubmit={async (e, modal) => {
          e.preventDefault();
          setConfirming(true);
          if (error !== null){
            setError('');
          }

          if (props.modal.onConfirming) {
            const error = await props.modal.onConfirming();
            if (typeof error === 'string') {
              setError(error)
              setConfirming(false);
              return;
            }
          }
          
          await modal.hide();
          setConfirming(false);
          if (props.modal.onConfirmed) {
            props.modal.onConfirmed();
          }
        }}
        onClose={() => {
          setError(null);
        }}
      >
        {props.modal.body}
      </ShowModalButton>
    </>
  );
};
