import React from 'react';
import { useHistory } from 'react-router-dom';
import { Loading } from '../chrome/Loading';
import { useSongFormQuery } from '../generated/graphql';
import { LibraryContext } from '../library/LibraryContext';
import { SongForm } from './SongForm';


interface Props {
  id: number
}


export const SongFormPage = (props: Props) => {

  const lib = React.useContext(LibraryContext)
  const history = useHistory();

  // Get the latest version of the song (we don't want to edit an old cached version)
  const [songQuery] = useSongFormQuery({ variables: {id: props.id}, requestPolicy: "network-only" });
  const song = songQuery.data?.song;

  if (!song || !songQuery.data) {
    return <Loading children={songQuery.fetching ? undefined : 'Error loading!'}/>;
  }

  return (
    <SongForm
      song={song}
      feelChoices={songQuery.data?.general.songFeelOptions}
      onSuccess={() => {
        history.push(lib.buildLink.song(props.id));
      }}
    />
  )
};