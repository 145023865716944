import React from "react"
import { TupleType } from "typescript";


export const ifSetWith = <T>(value: T | null | undefined, ifTrue: (v: T) => React.ReactNode): React.ReactNode => {
  if (value !== null && value !== undefined) {
    return ifTrue(value);
  }
  return null;
}


export const withValue = <T>(value: T, render: (value: T) => React.ReactNode): React.ReactNode => render(value);
