import cs from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import dates from '../dates';
import { LibraryDashboardFragment } from '../generated/graphql';
import { LibraryContext } from './LibraryContext';


interface Props {
  library: LibraryDashboardFragment
}


export const LibraryPageSongs = (props: Props) => {
  const lib = React.useContext(LibraryContext)
  const {library} = props;
  const [tab, setTab] = React.useState<'new' | 'used'>('new')

  return (
    <div className="card mb-5 bg-light">
      <div className="d-flex">
        <div className="d-flex me-auto p-3">Songs</div>
        <ul className="btn-group my-0 p-2">
          <button className={cs("btn btn-sm btn-light", tab === 'new' && 'active')} onClick={() => setTab('new')}>New</button>
          <button className={cs("btn btn-sm btn-light", tab === 'used' && 'active')} onClick={() => setTab('used')}>Most used</button>
        </ul>
      </div>
      <div className="list-group list-group-flush">
        {tab === 'new' && library.songsRecentlyAdded.map(s => 
          <Link key={s.id} to={lib.buildLink.song(s.id)} className="list-group-item list-group-item-action d-flex">
            {s.title}
            <small className="ms-auto text-muted text-right">{dates.timeSince(s.dateCreated)}</small>
          </Link>
        )}
        {tab === 'used' && library.songsMostUsed.map(s => 
          <Link key={s.id} to={lib.buildLink.song(s.id)} className="list-group-item list-group-item-action d-flex">
            {s.title}
            <small className="ms-auto text-muted text-right">&times;{s.usageCount}</small>
          </Link>
        )}
      </div>
    </div>
  );
};