import React from 'react';
import { UseQueryState } from 'urql';
import { Loading } from './Loading';


interface Props<T> {
  result: UseQueryState<T>
  error?: React.ReactNode
  fetching?: React.ReactNode
  children: (data: T) => React.ReactElement
}


export function Fetchable<T>(props: Props<T>): React.ReactElement {
  const { fetching, error, data } = props.result;
  if (data)
    return props.children(data)
  if (fetching) 
    return <>{props.fetching || <Loading/>}</>
  return <>{props.error}</> || <div>Oh no... {error?.message}</div>;
};