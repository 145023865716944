import { faCalendar } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cs from 'classnames';
import React from 'react';
import { FieldError } from 'react-hook-form';
import { Calendar } from './Calendar';
import { FirstDayOfWeek } from '../generated/graphql';
import { useElementId } from '../hooks/elementIds';
import styles from './CompactFormField.module.scss';
import { DateObj } from '../dates';


interface Props {
  error?: FieldError
  inputProps: React.HTMLProps<HTMLInputElement>
  label: string
  onDateSelected?: (value: DateObj) => void
  help?: string
  firstDayOfWeek: FirstDayOfWeek
  value: DateObj | null
}


export const CompactHookFormDateInput = (props: Props) => {
  const id = useElementId('HookFormDateInput');
  const {className = 'form-control', ...passThruProps} = props.inputProps;
  return (
    <div>

    <div className={cs("mb-3", styles.FormField)}>

      <div className="input-group mb-3">
          <input 
            id={id} 
            placeholder={props.label}
            className={cs(props.error && 'is-invalid', className)} 
            {...passThruProps}
          />
          <button className="btn btn-outline-secondary dropdown-toggle rounded-end" type="button" data-bs-toggle="dropdown" aria-expanded="false">
            <FontAwesomeIcon icon={faCalendar}/>
          </button>
          {props.onDateSelected &&
            <div className="dropdown-menu dropdown-menu-end p-0">
              <Calendar 
                firstDayOfWeek={props.firstDayOfWeek} 
                onSelect={value => props.onDateSelected && props.onDateSelected(value)}
                value={props.value}
              />
            </div>
          }
          <label htmlFor={id + '-field'} className="form-label">{props.label}</label>
          <div id={id + '-feedback'} className="invalid-feedback">{props.error?.message}</div>
        </div>
        <div id={id + '-help'} className="form-text">{props.help}</div>
      </div>
    </div>
  )
};
