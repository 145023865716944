import React from 'react';
import { LibrariesFragment, LibraryFragment, MeFragment, useAppQuery } from '../generated/graphql';


export interface AppContextData {
  me: MeFragment | null
  library: LibraryFragment | null,
  libraries: ReadonlyArray<LibrariesFragment> | null
  ready: boolean
  refresh: () => void
}

export const AppContext = React.createContext<AppContextData>({
  me: null,
  library: null,
  libraries: null,
  ready: false,
  refresh: () => null,
});


export const useAppContextState = (config: {libraryId: number | null}): AppContextData => {
  const [result, executeQuery] = useAppQuery({
    requestPolicy: "cache-and-network", 
    variables: {libraryId: config.libraryId},
  });

  // Manual refresh of query since urql 2.0.0 removed pollInterval
  React.useEffect(() => {
    if (!result.fetching) {
      const id = setTimeout(() => executeQuery({ requestPolicy: 'network-only' }), 60 * 1000);
      return () => clearTimeout(id);
    }
  }, [result.fetching, executeQuery]);

  // const [state, setState] = React.useState<Omit<AppContextData, 'me' | 'library' | 'libraries' | 'ready' | 'refresh'>>({})
  // state
  return {
    // ...state,
    me: result.data?.me || null,
    library: result.data?.library || null,
    libraries: result.data?.libraries || null,
    ready: !(!result.data && result.fetching),
    refresh: () => executeQuery({requestPolicy: 'network-only'})
  };
}
