import React from 'react';
import { CurrentSongFragment, PushLiveInput, TransposeInput, usePushLiveMutation } from '../generated/graphql';
import { LiveSyncContext } from './LiveSyncContext';


interface Props extends Omit<React.HTMLAttributes<HTMLButtonElement>, 'onClick'>{
  data: {
    songId: number
    setlistId?: number
    slide?: number
    transpose?: TransposeInput | null
  }
  onPushed?: (currentSong: CurrentSongFragment) => void
}


export const PushLiveBtn = ({data, ...props}: Props) => {
  const live = React.useContext(LiveSyncContext)
  const [, execute] = usePushLiveMutation();
  return (
    <button 
      {...props} 
      onClick={async () => {
        
        const setlistId = data.setlistId || live.currentSong?.setlist.id;
        if (setlistId) {
          const variables = {
            setlistId,
            songId: data.songId,
            ...data.transpose ? {transpose: data.transpose} : {},
            ...data.slide ? {slide: data.slide} : {},
          }
          const r = await execute({data: variables});
          if (r.data?.pushLive.__typename === 'CurrentSong') {
            return r.data.pushLive;
          }
        }
      }}
    />
  );
}