import { faChevronLeft, faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import dates, { DateObj } from '../dates';
import { FirstDayOfWeek } from '../generated/graphql';
import { CalendarDayButton } from './CalendarDayButton';


interface Props {
  value?: DateObj | null
  firstDayOfWeek: FirstDayOfWeek
  onSelect?: (day: DateObj) => void
}


export const Calendar = (props: Props) => {
  const [date, setDate] = React.useState(props.value || dates.now());
  const firstDayOfMonth = date.date(1);
  const emptyDaysBefore = (firstDayOfMonth.day() - (props.firstDayOfWeek === FirstDayOfWeek.Monday ? 1 : 0) + 7) % 7;

  const firstDayOfNextMonth = date.date(date.daysInMonth()).add(1, 'day');
  const emptyDaysAfter = (7 - firstDayOfNextMonth.day() + (props.firstDayOfWeek === FirstDayOfWeek.Monday ? 1 : 0)) % 7;

  const dayNames = props.firstDayOfWeek === FirstDayOfWeek.Monday 
    ? ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
    : ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  return (
    <div>
      
      {/* Month selection */}
      <div className='d-flex justify-content-between align-items-center' onClick={e => e.stopPropagation()}>
        <button className='btn btn-default' type='button' role='button' onClick={e => setDate(date.subtract(1, 'month'))}><FontAwesomeIcon icon={faChevronLeft}/></button>
        <div className='text-center fw-bold'>{date.format("MMMM YYYY")}</div>
        <button className='btn btn-default' type='button' onClick={() => setDate(date.add(1, 'month'))}><FontAwesomeIcon icon={faChevronRight}/></button>
      </div>

      {/* Day Selection */}
      <div style={{display: 'grid', gridTemplateColumns: 'repeat(7, 1fr [col-start])', textAlign: 'center'}}>

        {/* Day names */}
        {dayNames.map(n => <div key={n} className='px-1 border-bottom' onClick={e => e.stopPropagation()}>{n}</div>)}

        {/* Previous months dates */}
        {emptyDaysBefore <= 6 && [...new Array(emptyDaysBefore).keys()].reverse().map(i =>

          <CalendarDayButton key={i} onClick={props.onSelect} value={props.value || null}
            date={firstDayOfMonth.subtract(i + 1, 'days')} 
            muted
          />
        )}

        {/* This months dates */}
        {[...new Array(date.daysInMonth()).keys()].map(i =>
          <CalendarDayButton key={i} onClick={props.onSelect} value={props.value || null}
            date={firstDayOfMonth.add(i, 'days')} 
          />
        )}

        {/* Next months dates */}
        {emptyDaysAfter <= 6 && [...new Array(emptyDaysAfter).keys()].map(i =>
          <CalendarDayButton key={i} onClick={props.onSelect} value={props.value || null}
            date={firstDayOfNextMonth.add(i, 'days')} 
            muted
          />
        )}
      </div>
    </div>
  )
}