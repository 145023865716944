import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cs from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import { Fetchable } from '../chrome/Fetchable';
import { FullPage } from '../chrome/FullPage';
import { H1 } from '../chrome/H1';
import { Loading } from '../chrome/Loading';
import dates from '../dates';
import { LibraryFragment, MeFragment, useLibraryDashboardQuery } from '../generated/graphql';
import { buildLink } from '../routes';
import styles from './Library.module.scss';
import { LibraryContext } from './LibraryContext';
import { LibraryPageSongs } from './LibraryPageSongs';
import { getLibraryTabs, useLibrarySidebar } from './LibrarySidebar';


interface Props {
  id: number
  library: LibraryFragment | null
  onChange?: () => void
  me?: MeFragment
}


export const LibraryPage = (props: Props) => {
  const [result] = useLibraryDashboardQuery({
    variables: {
      id: props.id,
    },
    // pollInterval: props.pollInterval,
    requestPolicy: "cache-and-network",
    // pause: props.pause,
  });

  const [sidebarInfo,] = useLibrarySidebar({selected: 'dashboard'})

  const lib = React.useContext(LibraryContext)

  const library = result.data?.library;
  return (
    <FullPage
      sidebar={sidebarInfo}
      title={<H1>Library Dashboard</H1>}
      back={{to: buildLink.home()}}
      actions={
        <>
          {/* <Link to={lib.buildLink.songs()} className="btn btn-light me-3">All {result.data?.library?.songCount} song{result.data?.library?.songCount !== 1 && 's'}</Link>
          <Link to={lib.buildLink.setlists()} className="btn btn-light">All {result.data?.library?.setlistCount} setlist{result.data?.library?.setlistCount !== 1 && 's'}</Link> */}
      
        </>
      }
    >


      <div className="list-group">
        
        <div className="list-group-item pb-3 flex-fill">
          {library &&
            <div className="d-flex"> 
              {library.image?.medium.url && 
                <div className={cs(styles.LibraryImage, "me-3")}>
                  <img src={(library.image?.medium.url || '').replace('http://localhost:8000/', '/')} className="img-fluid" width="300" height="300"/>
                </div>
              }
              <div className="flex-fill d-flex flex-column justify-content-between"> 
                {/* <div className="d-flex">  */}
                  <h3 className="me-auto">
                    {library.title}
                  </h3>
                {/* </div> */}
                <div className="">
                  <p className="lead me-auto my-0" style={{lineHeight: 1.1}}>
                    {library.description}
                  </p>
                  <div className="d-none d-md-block mt-3">
                    {getLibraryTabs(props.id).filter(t => t.key !== 'dashboard').map(t => (
                      <Link to={t.to} key={t.key} className="me-4">
                        {/* <FontAwesomeIcon icon={t.icon} className='me-1'/> */}
                        {t.text}
                      </Link>
                    ))}
                  </div>
                  {/* <p className="lead me-auto">
                    {library.type === LibraryType.SharedLibrary && <>Shared with <Link to=".">{library.memberCount} member{library.memberCount !== 1 && 's'}</Link></>}
                    {library.type === LibraryType.Personal && <>Your private library</>}
                  </p> */}
                  {/* {library.ccli && <p className="ms-md-3 font-italic">CCLI Licence # {library.ccli}</p>} */}
                </div>
              </div>
            </div>
          }

        </div>
        {getLibraryTabs(props.id).map(t => (
          <Link to={t.to} key={t.key} className="list-group-item list-group-item-action d-md-none">
            <FontAwesomeIcon icon={t.icon} className='me-3'/>
            {t.text}
          </Link>
        ))}
      </div>
      <Fetchable result={result}>
        {data => {
          const { library } = data
          if (library) {
            // const dropdownItems = <>
            //   <a className="dropdown-item" href="#">Edit library</a>
            //   <a className="dropdown-item" href="#">Users &amp; access</a>
            //   <a className="dropdown-item" href="#">Song usage report</a>
            //   <div className="dropdown-divider"/>
            //   <a className="dropdown-item" href="#">Import data</a>
            //   <a className="dropdown-item" href={settings.serverURL + `/songs/${props.library.id}/export/all/zip-of-pdfs/`} target="_blank">Export zip archive of all songs PDF's</a>
            //   <div className="dropdown-divider"/>
            //   <a className="dropdown-item text-danger" href="#">Delete library</a>
            // </>
            return (
              <div>
                {/* <Toolbar 
                  burger
                  // back={{to: '/', text: 'Home'}}
                  more={dropdownItems}
                  main={<NavBarTwoLineBtn line1={result.data?.library?.title || 'Library Loading...'} line2="Home"/>}
                  onBurger={() => meContext.setSidebarOpen(true)}
                /> */}
                <div className="py-3 flex-fill">


                  <div className="row">
                    <div className="col-md">

                      <div className="card mb-4 bg-light">
                        <div className="card-header">LiveSync</div>
                        <div className="card-body font-italic">
                          Not currently implemented
                        </div>
                      </div>

                    </div>
                    <div className="col-md">

                      <div className="card mb-5 bg-light">
                        <div className="card-header">Upcoming setlists</div>
                        {library.upcoming_setlists.length === 0 ?
                          <div className="card-body txt-muted font-italic">There are none</div>
                          :
                          <div className="list-group list-group-flush">
                            {library.upcoming_setlists.map(s => 
                              <Link key={s.id} to={s.currentSong ? lib.buildLink.setlistsong(s.id, s.currentSong.id) : lib.buildLink.setlist(s.id)} className="list-group-item list-group-item-action d-flex">
                                {s.title}
                                <small className="ms-auto text-muted text-right">{dates.timeUntil(s.date)}</small>
                              </Link>
                            )}
                          </div>
                        }
                      </div>

                      <div className="card d-none d-md-block mb-5">
                        <div className="card-body">
                          <h5 className="card-title">Recent setlists</h5>
                        </div>
                        <div className="list-group list-group-flush">
                          {library.recent_setlists.map(s => 
                            <Link key={s.id} to={s.currentSong ? lib.buildLink.setlistsong(s.id, s.currentSong.id) : lib.buildLink.setlist(s.id)} className="list-group-item list-group-item-action d-flex">
                              {s.title}
                              <small className="ms-auto text-muted text-right">{dates.timeUntil(s.date)}</small>
                            </Link>
                          )}
                          {library.upcoming_setlists.length === 0 && <div className="list-group-item font-italic">There are none</div>}
                        </div>
                      </div>

                      {/* <h4 className="p-3">Upcoming setlists</h4>
                      <p><Link to=".">Browse all setlists</Link></p>
                      <Setlists libraryId={p.library || 0} setlists={library.upcoming_setlists as ReadonlyArray<SetlistsFragment> || []}/> */}
                    </div>
                    <div className="col-md">

                      <LibraryPageSongs library={library}/>

                    </div>
                  </div>

                </div>
              </div>
            )
          }
          return <Loading/>
        }}
      </Fetchable>

    </FullPage>
  );
  
};