import React from "react";
import { KeySelectProps } from "./KeySelect";
import { KeySelectDropdownMenu } from "./KeySelectDropdownMenu";
import { UseKeySelect, useKeySelect } from "./KeySelectHooks";


export const KeySelectSimple = (props: KeySelectProps) => {

  const key: UseKeySelect = useKeySelect(props)

  return (
    <ul className="navbar-nav">
      <li className="nav-item dropdown">

        <button
          data-bs-toggle="dropdown"
          title="Select Key"
          style={{ minWidth: "50px"  }}
          className="btn btn-light px-2 py-1 dropdown-toggle"
        >
          {key.keyDisplay}
        </button>
        <KeySelectDropdownMenu {...key} className="dropdown-menu-right" />
{/* 
        <div className={cs("dropdown-menu", props.align && 'dropdown-menu-right', 'dropdown-menu-iconed')}>
          {key.choices().map(k => (
            <Link
              key={k.value}
              className={cs('d-flex', 'dropdown-item', props.semitones === k.value ? "active" : "", k.value === 0 && 'font-weight-bold')}
              to={`?transpose=${k.value}&style=${props.style}`}
              onClick={e => key.handleTranspose(e, k.value)}
            >
              {key.currentKey ? (
                <>
                  <span className="me-auto">
                    {transposeNote(
                      props.originalKey,
                      k.value,
                      props.style === TransposeStyle.Flats ? 'b' : '#'
                    )}{" "}
                  </span>
                  <small className={cs(props.semitones === k.value ? "" : "text-muted",  k.value === 0 && 'font-weight-bold')}>{k.display}</small>
                </>
              ) : (
                k.display
              )}
            </Link>
          ))}

          <>
            <div className="dropdown-divider" />
            <Link
              to={`?transpose=${props.semitones}#`}
              onClick={key.handleStyleSharps}
              className={cs('dropdown-item', props.style === TransposeStyle.Sharps ? "active" : "")}
            >
              <span className="dropdown-item-texticon"><b>♯</b></span>
              Show with sharps
            </Link>
            <Link
              to={`?transpose=${props.semitones}b`}
              onClick={key.handleStyleFlats}
              className={cs('dropdown-item', props.style === TransposeStyle.Flats ? "active" : "")}
            >
              <span className="dropdown-item-texticon"><b>&#9837;</b></span>
              Show with flats 
            </Link>
            {key.currentKey && props.semitones !== 0 && props.onSave && (
              <>
                <div className="dropdown-divider" />
                <a className="dropdown-item" href="." onClick={e => {e.preventDefault(); if (props.onSave) props.onSave()}}>
                  <FontAwesomeIcon icon={icons.saveKey}/>Save song in this key
                </a>
              </>
            )}
          </>
        </div> */}
      </li>
    </ul>
  );
}
