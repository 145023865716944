import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { AccountSettingsCard } from '../account/AccountSettingsCard';
import { FullPage } from '../chrome/FullPage';
import { H1 } from '../chrome/H1';
import { Loading } from '../chrome/Loading';
import { TextField } from '../fields/TextField';
import { required } from '../fields/validators';
// import { required, TextField } from '../fields';
import { LibraryAccess, useDeleteLibraryMutation, useLibrarySettingsQuery, useRemoveLibraryImageMutation } from '../generated/graphql';
import { icons } from '../icons';
import { useLibrarySidebar } from '../library/LibrarySidebar';
import { DeleteLibraryBtn } from './DeleteLibraryBtn';
import { InviteMemberButton } from './InviteMemberButton';
import { LibraryForm } from './LibraryForm';
import { LibraryImageUpload } from './LibraryImageUpload';
import { MembersTable } from './MembersTable';

interface Props {
  id: number
}

export const SettingsPage = (props: Props) => {
  const [sidebarInfo, back] = useLibrarySidebar({selected: 'settings'})

  const [state, setState] = React.useState<'home' | 'library' | 'image'>('home')
  const [result] = useLibrarySettingsQuery({
    variables: {
      id: props.id,
    },
    requestPolicy: "cache-and-network",
  });

  const library = result.data?.library
  const {members, invites} = result.data?.library ?? {};

  const [removeImageResult, executeRemoveImage] = useRemoveLibraryImageMutation();

  // const useLib

  return (
    <FullPage
      title={<H1>Library Settings</H1>}
      sidebar={sidebarInfo}
      back={back}
    >
      
      {!library && <Loading/>}

      {library &&
        <div className='row'>

          <div className="my-3 col-md-6 col-xl-4">
            {state === 'library' &&  
              <AccountSettingsCard title="Basic Library Info" autoHeight>
                <LibraryForm 
                  library={library}
                  onSuccess={() => setState('home')}
                  onCancel={() => setState('home')}
                /> 
              </AccountSettingsCard>
            }
            {/* {state === 'image' &&   */}
              {/* <AccountSettingsCard title="Basic Library Info" autoHeight>
                <LibraryImageUpload libraryId={library.id} onDone={() => setState('home')}/>
              </AccountSettingsCard> */}
            {/* } */}
            {state !== 'library' &&
              <AccountSettingsCard 
                title="Basic Library Info" 
                onEdit={() => setState('library')} 
                autoHeight 
                footer={<DeleteLibraryBtn libraryId={library.id} libraryName={library.title} className="btn btn-link text-danger ps-0">Delete entire library</DeleteLibraryBtn>}
              >
                <dl className='mb-0'>
                  <dt>Title</dt>
                  <dd>{library.title}</dd>
                  <dt>Descripton</dt>
                  <dd>{library.description || <i className='text-muted'>Not set</i>}</dd>
                  <dt>CCLI License No.</dt>
                  <dd>{library.ccli ?? <i className='text-muted'>Not set</i>}</dd>
                  <dt>Logo</dt>
                  <dd>
                    {state === 'image' ? 
                      <LibraryImageUpload libraryId={library.id} onDone={() => setState('home')}/>
                    : 
                      <div>
                        <div>
                          {library.image 
                            ? <img src={library.image.medium.url} className=' d-block my-2' style={{maxWidth: '120px'}}/>
                            : <i className='text-muted'>Not set</i>
                          }
                        </div>
                        <button className='btn btn-link ps-0' onClick={() => setState('image')}>{library.image ? 'Change' : 'Upload'} Logo</button>
                        {library.image && 
                          <button className='btn btn-link' disabled={removeImageResult.fetching} onClick={() => {
                            if (window.confirm('Really remove this image from the library?')) {
                              executeRemoveImage({libraryId: library.id})
                            }
                          }}>{removeImageResult.fetching ? 'Removing...' : 'Remove Logo'}</button>
                        }
                      </div>
                    }
                  </dd>
                </dl>
              </AccountSettingsCard> 
            }

            <div className='pt-3'/>

          </div>
          <div className="my-3 col-md-6 col-xl-8">

          <AccountSettingsCard title="Members" autoHeight action={<InviteMemberButton className='btn btn-link' libraryId={library.id}>Add member</InviteMemberButton>}>

            {!members && <Loading/>}

            {members && invites && <MembersTable members={members} invites={invites}/>}

          </AccountSettingsCard>


          </div>
        </div>
      }
      <>
      </>
    </FullPage>
  )
};
