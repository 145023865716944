import React from 'react';
import { FullPage } from '../chrome/FullPage';
import { H1 } from '../chrome/H1';
import { useLibrarySidebar } from '../library/LibrarySidebar';

export const PresenterPage = () => {
  const [sidebarInfo, back] = useLibrarySidebar({selected: 'presenter'})
  return (
    <FullPage
      title={<H1>Presenter</H1>}
      sidebar={sidebarInfo}
      back={back}
    >
      Info about presenter here
    </FullPage>
  )
};
