import React from 'react';
import { useForm } from 'react-hook-form';
import { useDates } from '../dates';
import { CompactHookFormDateInput } from '../forms/CompactHookFormDateInput';
import { CompactHookFormInput } from '../forms/CompactHookFormInput';
import { CompactHookFormSelect } from '../forms/CompactHookFormSelect';
import { CompactHookFormTextarea } from '../forms/CompactHookFormTextarea';
import { FirstDayOfWeek } from '../generated/graphql';
import { LibraryContext } from '../library/LibraryContext';
import { useMutationFormHelpers } from '../songform/hooks';
import { LeaderSelectOptions } from './LeaderSelectOptions';


interface Values {
  title: string;
  date: string;
  notes: string;
  leader: string;
}

interface Props {
  userDateFormat: string
  firstDayOfWeek: FirstDayOfWeek
  defaultValues: Values
}


export const useSetlistForm = (props: Props) => {
  const dates = useDates({userFormat: props.userDateFormat});
  console.log(props.defaultValues)
  const lib = React.useContext(LibraryContext);
  const { register, handleSubmit, setError, clearErrors, setValue, watch, formState: {errors} } = useForm<Values>({ defaultValues: props.defaultValues });
  const formHelpers = useMutationFormHelpers({defaultValues: props.defaultValues, setError});
  const watchDate = watch('date');
  const watchDateFormatted = watchDate ? dates.parseUserDate(watchDate) : null;
  return {
    formHelpers,
    handleSubmit,
    prepareSubmitData: (data: Values) => ({
      ...data,
      date: dates.convertToGraphString(data.date),
      leader: data.leader ? parseInt(data.leader) : null,
    }),
    formFields : () => (
      <>
        <CompactHookFormInput label="Setlist title"  error={errors.title}
          inputProps={register('title', {required: 'Required', maxLength: {value: 200, message: 'Maximum of 200 characters'}})}
        />
        <div className="row">
          <div className="col-md">
            <CompactHookFormDateInput label={`Date (${props.userDateFormat})`}  error={errors.date}
              firstDayOfWeek={props.firstDayOfWeek}
              onDateSelected={val => {
                setValue('date', dates.formatAsUser(val));
                clearErrors('date');
              }}
              inputProps={register('date', {
                required: 'Required', 
                validate: (v) => 
                  dates.isValidUserDate(v)
                  ? true
                  : `Enter a valid date in the format ${props.userDateFormat}`
                })}
              value={watchDateFormatted?.isValid() ? watchDateFormatted : null}
            />
          </div>
          <div className="col-md">
            <CompactHookFormSelect label="Leader" error={errors.leader} inputProps={register('leader')}>
              <LeaderSelectOptions libraryId={lib.id}/>
            </CompactHookFormSelect>
          </div>
        </div>
        <CompactHookFormTextarea label="Notes"  error={errors.notes} inputProps={{...register('notes'), rows: 3}}/>
      </>
    ),
  };
};