import React from 'react';
import cs from 'classnames';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useElementId } from '../hooks/elementIds';


interface Choice<TValue> {
  icon: IconProp | string
  title?: string
  value: TValue
}

interface Props<TValue> {
  choices: readonly Choice<TValue>[]
  value: TValue
  onChange: (value: TValue) => void
  name?: string
}


export const RadioButtons = <TValue extends unknown>(props: Props<TValue>) => {
  const name = useElementId('radiobtn');
  
  return (

    <div className="btn-group bg-light" role="group" aria-label="Basic radio toggle button group">
      {props.choices.map((c,i) =>
        <React.Fragment key={i}>
          <input 
            type="radio" 
            className="btn-check" 
            name={props.name || name}
            id={name + '-' + i} autoComplete="off" checked={c.value === props.value}
            onChange={e => {
              if (e.target.checked) 
                props.onChange(c.value)
              }}
          />
          <label className="btn btn-light rounded px-2 py-1" htmlFor={name + '-' + i} title={c.title}>
            {typeof c.icon === 'string' ? c.icon : <FontAwesomeIcon icon={c.icon}/>}
          </label>
        </React.Fragment>
        )}

    </div>
  )
};

