import { faCopy } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { AppContext } from '../app/AppContext';
// import { required, TextField } from '../fields';
import { LibraryAccess, MembershipFragment, InviteFragment, useUpdateMemberAccessMutation } from '../generated/graphql';
import { icons } from '../icons';
import { LibraryContext } from '../library/LibraryContext';
import { settings } from '../settings';
import { orderdLibraryAccesses, prettyAccess } from './access';
import { CancelInviteBtn } from './CancelInviteBtn';
import { CopyInviteLinkBtn } from './CopyInviteLinkBtn';
import { MembersAccessDropdown } from './MemberAccessDropdown';
import cs from 'classnames';


interface Props {
  members: readonly MembershipFragment[]
  invites: readonly InviteFragment[]
}


export const MembersTable = (props: Props) => {
  const lib = React.useContext(LibraryContext)
  const app = React.useContext(AppContext)
  const [, executeUpdateAccess] = useUpdateMemberAccessMutation();

  const sortedInvites = [...props.invites].sort((a, b) => b.dateCreated.localeCompare(a.dateCreated));

  return (
    <table className="table table-hover">
      {orderdLibraryAccesses.map(access => (
        <React.Fragment key={access}>
          <tbody key={access}>
            <tr>
              <th colSpan={2}>{prettyAccess(access)}</th>
            </tr>

            {props.members.filter(m => m.access == access).map(m => (
              <tr key={m.id}>
                <td>
                  {m.user.fullName}
                  <div className="text-muted small">{m.user.email}</div>
                  {/* {m.dateJoined} */}
                </td>
                {/* <td>
                  {m.access === LibraryAccess.FullControl && 'Full-control'}
                  {m.access === LibraryAccess.ReadOnly && 'Read-only'}
                  {m.access === LibraryAccess.NoAccess && 'No access'}
                </td> */}
                <td className="text-end">
                  {lib.readOnly ? prettyAccess(m.access) : <MembersAccessDropdown member={m} disabled={m.user.id === app.me?.user?.id}/>}
                  {/* <MembersAccessDropdown member={m}/> */}
                  {/* <div className="dropdown">
                    <button className="btn btn-light dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      {m.access === LibraryAccess.FullControl && 'Full-control'}
                      {m.access === LibraryAccess.ReadOnly && 'Read-only'}
                      {m.access === LibraryAccess.NoAccess && 'No access'}
                    </button>
                    <div className="dropdown-menu dropdown-menu-right dropdown-menu-iconed" aria-labelledby="dropdownMenuButton">
                      <button className="dropdown-item">
                        <FontAwesomeIcon icon={m.access === LibraryAccess.ReadOnly ? icons.radioChecked : icons.radioUnchecked}/> Read-only
                      </button>
                      <button className="dropdown-item">
                      <FontAwesomeIcon icon={m.access === LibraryAccess.FullControl ? icons.radioChecked : icons.radioUnchecked}/> Full-control
                      </button>
                      <button className="dropdown-item">
                      <FontAwesomeIcon icon={m.access === LibraryAccess.NoAccess ? icons.radioChecked : icons.radioUnchecked}/> No-access
                      </button>
                    </div>
                  </div> */}
                </td>
              </tr>
            ))}
          </tbody>

          {access === LibraryAccess.ReadOnly && 
            <tbody>
              <tr>
                <th colSpan={2}>INVITED</th>
              </tr>

              {sortedInvites.map(i => (
                <tr key={i.id}>
                  <td className={cs(i.isExpired && 'text-muted')}>
                    {prettyAccess(i.access)} Invite
                    <div className="text-muted small">{i.toEmail}</div>
                    


                    <div className="">
                      {/* <input 
                        readOnly 
                        disabled
                        value={`${settings.serverURL}${i.inviteLink}`}
                        className='small'
                        onClick={e => e.currentTarget.setSelectionRange(0, e.currentTarget.value.length)}
                      /> */}
                    </div>

                  </td>
                  <td className='text-end'> 
                    {!i.isExpired && <>
                      <CancelInviteBtn invite={i} className="btn btn-sm btn-light "/>
                      <CopyInviteLinkBtn link={i.inviteLink}/>
                    </>}
                    {i.isExpired && <small className='text-muted'>Expired {i.dateExpires}</small>}
                  </td>
                </tr>
              ))}
            </tbody>
          }
        </React.Fragment>
      ))}
    </table>
  )
};
