import { faBars, faBurger, faChevronRight, faSearch, faUser } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'bootstrap/dist/js/bootstrap';
import { Link } from "react-router-dom";
import { LogoutBtn } from '../auth/LogoutBtn';
import { OffCanvas } from '../chrome/OffCanvas';
import { LibrariesFragment, MeFragment } from '../generated/graphql';
import { LibraryIcon } from '../library/LibraryIcon';
import { LibrarySelect } from '../LibrarySelect';
import { useLiveSyncContext } from '../livesync/LiveSyncContext';
import { LiveSyncDropdown } from '../livesync/LiveSyncDropdown';
import { buildLink, isRoute } from '../routes';
import songlibLogo from '../svg/songlib-logo.svg';
import { AppContext } from './AppContext';
import React from 'react';
import cs from 'classnames';
import { AddLibraryButton } from '../library/AddLibraryButton';


interface Props {
  libraries: readonly LibrariesFragment[]
  libraryId: number | null
  me: MeFragment | null
}

export const NavBar = ({libraryId, libraries , me}: Props) => {
  const library = libraries.filter(l => l.id === libraryId)[0] || null;
  const liveSync = useLiveSyncContext();
  
  // Prompt to select a library if none is selected
  return (
    <nav className="navbar navbar-expand bg-light border-bottom mb-md-2 mb-lg-0">
    {/* <nav className="navbar navbar-expand-lg" style={{background: '#e7eef7'}}> */}
      <div className="d-flex w-100">
        {/* {appContextState.libraries && <LibrarySelect options={appContextState.libraries}/>} */}
        {/* <a className="navbar-brand" href="#">Navbar</a> */}
        {/* <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button> */}
        {/* <div className="collapse navbar-collapse" id="navbarSupportedContent"> */}
          <ul className="navbar-nav me-auto">

            <li className="nav-item dropdown d-md-none">
              <a className="nav-link px-3" role="button" href="#navbarOffCanvasLeft" data-bs-toggle="offcanvas">
                <FontAwesomeIcon icon={faBars}/>
              </a>
            </li>
            {library && 
              <li className="nav-item d-flex align-items-center d-md-none" data-bs-toggle="offcanvas" data-bs-target="#navbarOffCanvasLeft">
                <div>
                  <LibraryIcon library={library} size={24}/>
                </div>
                {/* {library.title} */}
                <div style={{fontSize: '10px', maxWidth: '50px', maxHeight: '32px', lineHeight: '1', overflow: 'hidden'}}>{library?.title}</div>
              </li>
            }


            <li className="nav-item dropdown d-none d-md-block">
              <a className="nav-link dropdown-toggle border-end px-3" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                {library && <LibraryIcon library={library} size={20}/>}
                {library?.title || 'Select a library'}
              </a>
              <ul className="dropdown-menu">
                {libraries?.map(l => 
                  <li key={l.id}>
                    <Link className="dropdown-item d-flex" to={buildLink.library(l.id)}>
                      <LibraryIcon library={l} size={24}/>
                      {l.title}
                    </Link>
                  </li>
                )}
                <li><hr className="dropdown-divider"/></li>
                <li><AddLibraryButton className="dropdown-item">Add library...</AddLibraryButton></li>
              </ul>
            </li>
            {libraryId && <>
              <li className="nav-item">
                <Link className={cs("nav-link", window.location.pathname.startsWith(buildLink.songs(libraryId)) && 'active')} aria-current="page" to={buildLink.songs(libraryId)}>Songs</Link>
              </li>
              <li className="nav-item">
                <Link className={cs("nav-link", window.location.pathname.startsWith(buildLink.setlists(libraryId)) && 'active')} aria-current="page" to={buildLink.setlists(libraryId)}>Sets</Link>
              </li>
              <li className="nav-item d-none d-md-block">
                <Link className={cs("nav-link", window.location.pathname.startsWith(buildLink.presenter(libraryId)) && 'active')} aria-current="page" to={buildLink.presenter(libraryId)}>Presenter</Link>
              </li>
              <li className="nav-item d-none d-md-block">
                <Link className={cs("nav-link", window.location.pathname.startsWith(buildLink.settings(libraryId)) && 'active')} aria-current="page" to={buildLink.settings(libraryId)}>Settings</Link>
              </li>
            </>}
          </ul>
          <form className="ms-auto d-none d-xl-block" role="search">
            <div className="input-group input-group-sm">
              <input type="text" className="form-control border-secondary" placeholder="Search songs.." aria-label="Search songs.." aria-describedby="button-addon2"/>
              <button className="btn btn-outline-secondary" type="button" id="button-addon2"><FontAwesomeIcon icon={faSearch}/></button>
            </div>
          </form>
          <ul className="navbar-nav mb-0 ms-3">
            <li className="nav-item dropdown d-none d-md-flex">
              <a className="nav-link dropdown-toggle border-start px-3" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                <FontAwesomeIcon icon={faUser} className="me-2"/>
                {me?.user?.shortName || 'User'}
              </a>
              <ul className="dropdown-menu dropdown-menu-end">
                <li><Link to={buildLink.account} className='dropdown-item' href="./">My account settings</Link></li>
                <li><hr className="dropdown-divider"/></li>
                {me?.user?.isStaff && <>
                  <li><a className='dropdown-item' href="https://songlib.com/songlib-admin/">Django admin</a></li>
                  <li><hr className="dropdown-divider"/></li>
                </>}
                <li><a className='dropdown-item' href="https://songlib.com/whats-new/348/">What's new?</a></li>
                <li><a className='dropdown-item' href="https://songlib.uservoice.com/">Give feedback or contact support</a></li>
                <li><hr className="dropdown-divider"/></li>
                <li><LogoutBtn className='dropdown-item'>Logout...</LogoutBtn></li>
              </ul>
            </li>
            <li className="nav-item dropdown ">
              <a className="nav-link dropdown-toggle border-none px-3" style={{background: liveSync.enabled ? '#9defa6' : undefined}} href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Live
              </a>
              <ul className="dropdown-menu dropdown-menu-end p-0">
                <LiveSyncDropdown/>
              </ul>
            </li>
          </ul>
        {/* </div> */}
      </div>
      <OffCanvas 
        id="navbarOffCanvasLeft" 
        title={<img src={songlibLogo} title="Songlib" alt="Songlib" style={{width: '80px'}}/>}
        headerClassName="pb-1"
        flush
      >

        <div id="navbarOffCanvasLeftUser" className='px-3 pb-3'>
          {me?.user && 
            <div className='d-flex align-items-center'>
              <div>Logged in as <b>{me?.user?.shortName}</b></div>
              <LogoutBtn className='btn btn-light ms-auto'/>
            </div>
          }
        </div>

        <div>
          {libraries && 
            <div className='py-3 text-center'>
              <LibrarySelect options={libraries} value={libraryId} className='btn btn-light dropdown-toggle fw-bold'/>
            </div>
          }
        </div>
        
        
        {libraryId && 
          <ul className="list-group list-group-flush border-top">
            <Link className="list-group-item ist-group-item-action d-flex align-items-center" to={buildLink.songs(libraryId)}>Songs <FontAwesomeIcon icon={faChevronRight} className="ms-auto"/></Link>
            <Link className="list-group-item ist-group-item-action d-flex align-items-center" to={buildLink.setlists(libraryId)}>Sets <FontAwesomeIcon icon={faChevronRight} className="ms-auto"/></Link>
            <Link className="list-group-item ist-group-item-action d-flex align-items-center" to={buildLink.presenter(libraryId)}>Presenter <FontAwesomeIcon icon={faChevronRight} className="ms-auto"/></Link>
            <Link className="list-group-item ist-group-item-action d-flex align-items-center" to={buildLink.settings(libraryId)}>Settings <FontAwesomeIcon icon={faChevronRight} className="ms-auto"/></Link>
          </ul>
        }

        <div className='bg-light pt-3 border-top border-bottom'/>

        <ul className="list-group list-group-flush border-bottom">
          <Link className="list-group-item ist-group-item-action d-flex align-items-center" to={buildLink.account()}>Account <FontAwesomeIcon icon={faChevronRight} className="ms-auto"/></Link>
          <a className="list-group-item ist-group-item-action d-flex align-items-center" href="http://songlib.uservoice.com/" target="_blank">Contact supper <FontAwesomeIcon icon={faChevronRight} className="ms-auto"/></a>
          <a className="list-group-item ist-group-item-action d-flex align-items-center" href="http://songlib.uservoice.com/knowledgebase" target="_blank">Knowledge base <FontAwesomeIcon icon={faChevronRight} className="ms-auto"/></a>
          <a className="list-group-item ist-group-item-action d-flex align-items-center" href="http://songlib.uservoice.com/forums/206919" target="_blank">Suggest a feature <FontAwesomeIcon icon={faChevronRight} className="ms-auto"/></a>
        </ul>

        <div className='bg-light pt-3 border-bottom'/>

      </OffCanvas>
    </nav>
      
  );
};
