import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { AppContext } from '../app/AppContext';
import { FullPage } from '../chrome/FullPage';
import { H1 } from '../chrome/H1';
import { useModal } from '../chrome/Modal';
import { LibraryContext } from '../library/LibraryContext';
import { useLibrarySidebar } from '../library/LibrarySidebar';
import { routes } from '../routes';
import { SetlistPage } from '../setlist/SetlistPage';
import { SetlistsAddButton } from './SetlistsAddButton';
import { SetlistsContainer } from './SetlistsContainer';


export interface BaseProps {
  // onAddClick?: () => void
  // selectedId?: number
  // toSong?: boolean
}

interface Props extends BaseProps {
  // readonly setlists: readonly SetlistsFragment[]
}


export const SetlistsPage = (props: Props) => {
  const lib = React.useContext(LibraryContext)
  const app = React.useContext(AppContext)
  const [sidebarInfo, back] = useLibrarySidebar({selected: 'setlists'});
  const modal = useModal();
  const history = useHistory();
  const prefs = app.me?.preferences;
  return (
    <Switch>

      <Route path={routes.setlists} exact>
        {p => (

          <FullPage
            sidebar={sidebarInfo}
            title={<H1>Setlists</H1>}
            back={back}
            actions={
              !lib.readOnly && prefs &&
                <SetlistsAddButton className='btn btn-light px-2 py-1' userDateFormat={prefs?.dateFormat} firstDayOfWeek={prefs.firstDayOfWeek}>
                  <FontAwesomeIcon icon={faPlus} className="d-md-none"/>
                  <span className='d-none d-md-inline'> New</span>
                </SetlistsAddButton>
            }
            >
            <div>
            <div className="">
            </div>
            {/* <Toolbar burger main={<NavBarTwoLineBtn line1={result.data?.library?.title || 'Library Loading...'} line2="Setlists"/>} onBurger={() => meContext.setSidebarOpen(true)}/> */}
            {/* <h3 className="p-3 mt-navbar">All Setlists</h3> */}
            <SetlistsContainer {...props}/>
            </div>
          </FullPage>
        )}
      </Route>

      <Route path={routes.setlist}>
        {p => (
          <SetlistPage id={parseInt(p.match?.params.setlist as string)} />
        )}
      </Route>
      
    </Switch>
  )
};
