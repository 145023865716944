
import { useForm } from 'react-hook-form';
import { CompactHookFormSelect } from '../forms/CompactHookFormSelect';
import { FirstDayOfWeek, useUpdateMyPreferencesMutation } from '../generated/graphql';
import { useMutationFormHelpers } from '../songform/hooks';


interface Values {
  // email: string
  // firstName: string
  // lastName: string
  dateFormat: string
  firstDayOfWeek: string
}


interface Props {
  defaultValues: Values
  dateFormatChoices: readonly string[]
  firstDayOfWeekChoices: readonly string[]
  onSuccess: () => void
  onCancel: () => void
}


export const AccountForm = (props: Props) => {

  const defaultValues = {
    dateFormat: props.defaultValues.dateFormat,
    firstDayOfWeek: props.defaultValues.firstDayOfWeek as FirstDayOfWeek,
  }
  const { register, handleSubmit, setError, formState: {errors, isValid}} = useForm({ defaultValues: defaultValues});
  const formHelpers = useMutationFormHelpers({defaultValues, setError});

  const [, executeUpdate] = useUpdateMyPreferencesMutation();

  return (
    <form onSubmit={handleSubmit(async data => {

      formHelpers.clearMutationError();
      const r = await executeUpdate({ data: { dateFormat: data.dateFormat, firstDayOfWeek: data.firstDayOfWeek }});
      if (r.data?.updateMyPreferences.__typename === 'Me') {
        props.onSuccess();
      }
      else {
        formHelpers.processMutationError(r, r.data?.updateMyPreferences);
      }

    })}>
      
      <CompactHookFormSelect label="Date format"  error={errors.dateFormat}
        inputProps={register('dateFormat', {required: 'Required'})}
      >
        {props.dateFormatChoices.map(c => <option key={c} value={c}>{c}</option>)}
      </CompactHookFormSelect>
      
      <CompactHookFormSelect label="First day of the week"  error={errors.firstDayOfWeek}
        inputProps={register('firstDayOfWeek', {required: 'Required'})}
      >
        {props.firstDayOfWeekChoices.map(c => <option key={c} value={c}>{c}</option>)}
      </CompactHookFormSelect>
      
      {formHelpers.mutationError && <div className="alert alert-danger" role="alert">{formHelpers.mutationError}</div>}

      <div className='d-flex flex-row-reverse mt-4'>

        <button type="submit" className='btn btn-dark'>Save Preferences</button>
        <button className='btn btn-light me-3' onClick={props.onCancel}>Cancel</button>
      </div>

    </form>
  )

};