import { useForm } from 'react-hook-form';
import { CompactHookFormInput } from '../forms/CompactHookFormInput';
import { useUpdateMyUserMutation } from '../generated/graphql';
import { useMutationFormHelpers } from '../songform/hooks';


interface Values {
  email: string
  firstName: string
  lastName: string
}


interface Props {
  defaultValues: Values
  onSuccess: () => void
  onCancel: () => void
}


export const UserForm = (props: Props) => {

  const { register, handleSubmit, setError, formState: {errors} } = useForm({ defaultValues: props.defaultValues });
  const formHelpers = useMutationFormHelpers({defaultValues: props.defaultValues, setError});
  const [, executeUpdate] = useUpdateMyUserMutation();

  return (
    <form onSubmit={handleSubmit(async data => {

      formHelpers.clearMutationError()
      const r = await executeUpdate({ data });
      if (r.data?.updateMyUser.__typename === 'Me') {
        props.onSuccess();
      }
      else {
        formHelpers.processMutationError(r, r.data?.updateMyUser)
      }

    })}>
      
      <CompactHookFormInput label="Email address"  error={errors.email}
        inputProps={register('email', {required: 'Required'})}
      />

      <CompactHookFormInput label="First name"  error={errors.firstName}
        inputProps={register('firstName', {required: 'Required'})}
      />

      <CompactHookFormInput label="Last name"  error={errors.lastName}
        inputProps={register('lastName', {required: 'Required'})}
      />

      {formHelpers.mutationError && <div className="alert alert-danger" role="alert">{formHelpers.mutationError}</div>}

      <div className='d-flex flex-row-reverse mt-4'>

        <button type="submit" className='btn btn-dark'>Save User Info</button>
        <button className='btn btn-light me-3' onClick={props.onCancel}>Cancel</button>
      </div>

    </form>
  )

};