import React from 'react';
import { useForm } from 'react-hook-form';
import { ShowModalButton } from '../chrome/ShowModalButton';
import { CompactHookFormInput } from '../forms/CompactHookFormInput';
import { SongFragment, useCreateSongMutation } from '../generated/graphql';
import { useMutationFormHelpers } from '../songform/hooks';


interface Props extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>{
  onSuccess: (song: SongFragment) => void;
  libraryId: number
}


export const AddSongButton = (props: Props) => {
  const [result, executeCreate] = useCreateSongMutation();
  const {onClick, children, onSuccess, libraryId, ...passThru} = props;
  const defaultValues = {title: ''};
  const { register, handleSubmit, setError, formState: {errors} } = useForm({ defaultValues });
  const formHelpers = useMutationFormHelpers({defaultValues: defaultValues, setError});


  return (
    <ShowModalButton
      name="AddSongButton"
      button={{...passThru, children: props.children}}
      modal={{

        title: "Add a new song" ,
        footer: 
          <>
            <button className='btn btn-default' data-bs-dismiss='modal' type='button'>
              Cancel
            </button>
            <button type="submit" disabled={result.fetching} className="btn btn-dark">Continue</button>
          </>
  
        
      }}
      onSubmit={(event, modal) => handleSubmit(async data => {
        
        formHelpers.clearMutationError()
        const r = await executeCreate({ libraryId: props.libraryId, data: {title: data.title} });
        if (r.data?.createSong.__typename === 'CreateSongSuccess') {
          await modal.hide();
          props.onSuccess(r.data.createSong.song);
        }
        else {
          formHelpers.processMutationError(r, r.data?.createSong)
        }

      })(event)}
    >

      <CompactHookFormInput label="Song Title"  error={errors.title}
        inputProps={register('title', {required: 'Required', maxLength: {value: 200, message: 'Max of 200 characters'}})}
      />

      {formHelpers.mutationError && <div className="alert alert-danger" role="alert">{formHelpers.mutationError}</div>}

    </ShowModalButton>
  );
};
