import React from 'react';
import { FullPageContentPane } from '../chrome/FullPageContentPane';
import { H1 } from '../chrome/H1';
import { Loading } from '../chrome/Loading';
import { MeFragment, useMyAccountQuery } from '../generated/graphql';
import { buildLink } from '../routes';
import { AccountForm } from './PreferenceForm';
import { AccountSettingsCard } from './AccountSettingsCard';
import { UserForm } from './UserForm';


interface Props {
  me: MeFragment | null
}


export const AccountSettingsPage = ({me}: Props) => {

  const [query, reload] = useMyAccountQuery()
  const [state, setState] = React.useState<'home' | 'prefs' | 'user'>('home')

  const {user, preferences} = query.data?.me || {};

  return (
    <FullPageContentPane
      title={<H1>My account settings</H1>}
      back={{to: buildLink.home()}}
    >

      {user && preferences && query.data?.general ?
        <>
          {/* {state === 'home' && <> */}
          
            <div className='row'>

              <div className="my-3 col-md-6 col-lg-4">
                {state === 'user' ?  
                  <AccountSettingsCard title="About you" >
                    <UserForm 
                      defaultValues={{email: user.email, firstName: user.firstName, lastName: user.lastName}}
                      onSuccess={() => setState('home')}
                      onCancel={() => setState('home')}
                    /> 
                  </AccountSettingsCard>
                :
                  <AccountSettingsCard title="About you" onEdit={() => setState('user')} >
                    <dl className='mb-0'>
                      <dt>Email</dt>
                      <dd>{user.email}</dd>
                      <dt>First name</dt>
                      <dd>{user.firstName}</dd>
                      <dt>Last name</dt>
                      <dd>{user.lastName}</dd>
                    </dl>
                  </AccountSettingsCard>  
                }
              </div>

              <div className="my-3 col-md-6 col-lg-4">
                {state === 'prefs' ?  
                  <AccountSettingsCard title="Preferences" >
                    <AccountForm 
                      defaultValues={{...user, ...preferences}}
                      dateFormatChoices={query.data?.general.dateFormatOptions}
                      firstDayOfWeekChoices={query.data.firstDayOfWeekEnum?.enumValues?.map(v => v.name) || []}
                      onSuccess={() => setState('home')}
                      onCancel={() => setState('home')}
                    /> 
                  </AccountSettingsCard>
                  :
                  <AccountSettingsCard title="Preferences" onEdit={() => setState('prefs')} >
                    <p className='fst-italic'>Control how dates and calendars are displayed.</p>
                    <dl className='mb-0'>
                      <dt>Date format</dt>
                      <dd>{preferences.dateFormat}</dd>
                      <dt>First day of the week</dt>
                      <dd>{preferences.firstDayOfWeek}</dd>
                    </dl>
                  </AccountSettingsCard>
                }
              </div>

              <div className="my-3 col-md-6 col-lg-4">
                <AccountSettingsCard title="Login details" >
                  <dl className='mb-0'>
                  <dt>Email</dt>
                  <dd>{user.email}</dd>
                  {/* <dt>Password</dt> */}
                  <dd><button className="btn btn-link px-0">Change password</button></dd>
                </dl>
                </AccountSettingsCard>
              </div>

            </div>
          
          {/* </>} */}

          {/* {state === 'prefs' && 
            <AccountForm 
              defaultValues={{...user, ...preferences}}
              dateFormatChoices={query.data?.general.availableDateFormats}
              firstDayOfWeekChoices={query.data.firstDayOfWeekEnum?.enumValues?.map(v => v.name) || []}
              onSuccess={() => setState('home')}
            /> 
          } */}
        
        </>
        : <Loading/>
      }

    </FullPageContentPane>
  )
};
