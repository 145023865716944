import React from 'react';
import { LoginForm } from './auth/LoginForm';
import logo from './svg/songlib-logo.svg';


interface Props {
  onSuccess: () => void
}


export const Unauthenticated = (props: Props) => {
  return (
    <div className="d-flex h-100 align-items-center">
      <div className="flex-fill text-center">
        <p style={{maxWidth: '150px'}} className="mx-auto">
          <img src={logo} className="img-fluid" alt="Songlib Logo"/>
        </p>
        <p className="lead">Your chord chart library</p>

        <div style={{maxWidth: '400px'}} className="mx-auto">
          <LoginForm onSuccess={props.onSuccess}/>
        </div>
      </div>
    </div>
  );
};