import 'bootstrap/dist/js/bootstrap';
import { Route, Switch } from "react-router";
import { useHistory, useRouteMatch } from "react-router-dom";
import { AccountSettingsPage } from '../account/AccountSettingsPage';
import { Loading } from '../chrome/Loading';
import { HomePage } from '../home/HomePage';
import { Library } from '../library/Library';
import { LiveSyncContext, useLiveSyncLibraryContextState } from '../livesync/LiveSyncContext';
import { LiveSyncDropdown } from '../livesync/LiveSyncDropdown';
import { buildLink, routes } from '../routes';
import { Unauthenticated } from '../Unauthenticated';
import { AppContext, useAppContextState } from './AppContext';
import { NavBar } from './NavBar';


export const App = () => {
  const libraryMatch = useRouteMatch<{library: string}>(routes.library);
  const libraryId = libraryMatch ? parseInt(libraryMatch.params.library) : null;
  const appContextState = useAppContextState({libraryId});
  const history = useHistory();
  const liveSyncData = useLiveSyncLibraryContextState({libraryId});

  // Prompt to login in not authenticated
  if (appContextState.me?.isAuthenticated === false) {
    return <Unauthenticated onSuccess={() => {
      history.replace(buildLink.home());
      appContextState.refresh();
    }}/>;
  }

  if (!appContextState.ready) return <Loading/>;

  const {library, libraries} = appContextState;

  // Prompt to select a library if none is selected
  return (
    <div className='h-100 d-flex flex-column'>
      <LiveSyncContext.Provider value={liveSyncData}>
        <NavBar libraries={libraries || []} libraryId={libraryId} me={appContextState.me}/>
        
        {libraryId !== null && (
          <AppContext.Provider value={appContextState}>
            <div className="d-flex flex-fill overflow-auto flex-column">
              <div className="flex-fill overflow-auto">
                {liveSyncData.sidebar 
                  ? (
                    <div className='d-flex h-100'>
                      <Library id={libraryId} library={appContextState.library} me={appContextState.me || undefined}/>
                      <LiveSyncDropdown allowPush className='border-start'/>
                    </div>
                    )
                  : <Library id={libraryId} library={appContextState.library} me={appContextState.me || undefined}/>
                }
              </div>
            </div>
          </AppContext.Provider>
        )}

        {libraryId === null && (
          <Switch>
            <Route path={routes.account}>
              <AccountSettingsPage  me={appContextState.me}/>
            </Route>
            <Route>
              <HomePage me={appContextState.me} libraries={appContextState.libraries}/>
            </Route>
          </Switch>
        )}
      </LiveSyncContext.Provider>
    </div>
  );
};
