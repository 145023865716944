
interface Groupable {
  title: string
}


interface ByLetter<T extends Groupable> {
  [letter: string]: ReadonlyArray<T>;
}

const digitRe = /^[0-9]$/gi;


export const groupByLetter = <T extends Groupable>(songs: ReadonlyArray<T>) => {
  const reducer = (prev: ByLetter<T>, curr: T) => {
    var letter = curr.title.substr(0, 1);
    letter = digitRe.test(letter) ? "0-9" : letter;

    return { ...prev, [letter]: [...(prev[letter] || []), curr] };
  };

  const byLetter = songs.reduce<ByLetter<T>>(reducer, {});

  return Object.keys(byLetter)
    .sort()
    .map(letter => ({ letter, songs: byLetter[letter] }));
};
