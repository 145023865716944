import React from 'react';
import { useHistory } from 'react-router-dom';
import { ModalConfirmButton } from '../chrome/ModalConfirmButton';
import { useLogoutMutation } from '../generated/graphql';
import { routes } from '../routes';


interface Props extends React.HTMLProps<HTMLButtonElement> {

}


export const LogoutBtn = (props: Props) => {
  const [result, executeMutation] = useLogoutMutation();
  const {onClick, children, ...passThru} = props;
  const history = useHistory();
  return (
    <>
      <ModalConfirmButton
        {...passThru}
        modal={{
          name: "ConfirmLogout",
          confirmText: result.fetching ? 'Logging out...' : 'Logout',
          confirmDisabled: result.fetching,
          body: 'Are you sure you want to log out?',
          onConfirming: async () => {
            // return 'Error logging out2'
            const r = executeMutation();
            // if (r.data?.logout.__typename !== 'Me') {
            //   return 'Error logging out';
            // }
          },
          onConfirmed: async () => {
            history.push(routes.home);
          },
          title: 'Please confirm'
        }}
      >
        {children || 'Logout'}
      </ModalConfirmButton>
    </>
  );
};
