import React from 'react';
import { useUploadLibraryImageMutation } from '../generated/graphql';


interface Props {
  libraryId: number
  onDone: () => void
}

export const LibraryImageUpload = (props: Props) => {
  const [executeResult, executeUpload] = useUploadLibraryImageMutation();
  const ref = React.useRef<HTMLInputElement>(null);
  const [error, setError] = React.useState<string | null>(null)

  const upload = async (file: File) => {

    const r = await executeUpload({
      data: {
        libraryId: props.libraryId, 
        file: {
          content: file,
          name: file.name,
          size: file.size,
          type: file.type,
          lastModified: (new Date(file.lastModified)).toISOString(),
        }}
    });
    if (r.data?.uploadLibraryImage.__typename === 'Library') {
      props.onDone();
    }
    else {
      setError(r.data?.uploadLibraryImage.message || 'Unknown Error')
    }
  }

  return (
    <div>

      <div className="mb-3" style={executeResult.fetching ? {display: 'none'} : undefined}>
        <label htmlFor="formFile" className="form-label">Select a new library image from your device</label>
        <input ref={ref} 
          type="file" 
          className='form-control' 
          // accept="image/*" 
          disabled={executeResult.fetching} 
          onChange={async e => {
            setError(null);
            
            const file = e.currentTarget.files ? e.currentTarget.files[0] : null;
            if (file) {
              upload(file)
            }

          }}
        />
      </div>

      {executeResult.fetching && 
        <div className="progress" style={{height: '30px'}}>
          <div className="progress-bar progress-bar-striped progress-bar-animated w-100" role="progressbar" >
            Uploading Image
          </div>
        </div>
      }

      {error && <div className='alert alert-danger'>{error}</div>}

      <button className='btn btn-link ps-0' onClick={() => props.onDone()}>Cancel</button>
    </div>
  )
}