import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';
import { icons } from '../icons';
import cs from 'classnames';
import { H1 } from './H1';


export interface BackInfo {
  to: string,
  text?: string,
}

interface Props {
  back?: BackInfo
  title?: React.ReactNode
  actions?: React.ReactNode
}


export const SidebarHeader = (props: Props) => (
  <div className="d-flex align-items-center border-bottom" style={{lineHeight: '50px'}}>
    {props.back &&
      <Link to={props.back.to} className="btn btn-link rounded-0 px-3" title="Back to setlists">
        {props.back.text || <FontAwesomeIcon icon={icons.back}/>}
      </Link>
    }
    <H1 className={cs("py-3", !props.back && 'ms-3')} style={{whiteSpace: 'nowrap', overflowX: 'hidden'}}>
      {props.title || <>&nbsp;</>}
    </H1>
    {/* <button className="btn btn-link rounded-0 px-3" title="Menu">
      <FontAwesomeIcon icon={icons.more}/>
    </button> */}

    {/* {collapseBtn && 
      <button className="btn btn-link rounded-0" title="Back to setlists" onClick={toggleSidebar}>
        <FontAwesomeIcon icon={faAngleLeft} className="me-1"/>
        <FontAwesomeIcon icon={faBars}/>
      </button>
    } */}
    {props.actions && <div className="ms-auto">{props.actions}</div>}
  </div>
);