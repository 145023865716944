import cs from 'classnames';
import React from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { FullPage } from '../chrome/FullPage';
import { H1 } from '../chrome/H1';
import { CompactHookFormInput } from '../forms/CompactHookFormInput';
import { CompactHookFormSelect } from '../forms/CompactHookFormSelect';
import { CompactHookFormTextarea } from '../forms/CompactHookFormTextarea';
import { SongFragment, useUpdateSongMutation } from '../generated/graphql';
import { LibraryContext } from '../library/LibraryContext';
import { useMutationFormHelpers } from './hooks';
import { SongChordChartField } from './SongChordChartField';

interface Props {
  song: SongFragment
  className?: string
  feelChoices: readonly string[]
  onSuccess: () => void
}


export const SongForm = ({song, ...props}: Props) => {
  
  const lib = React.useContext(LibraryContext)

  const [chordChart, setChordChart] = React.useState(song.chordChart || '')

  const [, executeUpdate] = useUpdateSongMutation();
  const defaultValues = {
    author: song.author,
    ccli: song.ccli,
    chordChart: song.chordChart,
    copyright: song.copyright,
    feel: song.feel,
    key: song.key || '',
    notes: song.notes,
    tempo: song.tempo,
    theme: song.theme,
    timeSig: song.timeSig,
    title: song.title,
    url: song.url,
    year: song.year,
  };
  const { register, handleSubmit, setError, formState: {errors} } = useForm({ defaultValues });
  const formHelpers = useMutationFormHelpers({defaultValues, setError});
  
  return (
    <form className="h-100" onSubmit={handleSubmit(async data => {
    
      formHelpers.clearMutationError();
      const r = await executeUpdate({songId: song.id, data: { ...data, chordChart: chordChart } });
      if (r.data?.updateSong.__typename === 'UpdateSongSuccess') {
        props.onSuccess();
      }
      else {
        formHelpers.processMutationError(r, r.data?.updateSong)
      }

    })}>
      <FullPage
        title={<H1>Edit Song</H1>}
        actions={
          <div className='d-flex flex-row-reverse'>
            <button type="submit" className='btn btn-dark'>Save Song</button>
            <Link to={lib.buildLink.song(song.id)} className='btn btn-light me-3'>Cancel</Link>
          </div>
        }
        sidebar={{
          title: "Song",
          children: 'sidebar content',
          actions: null,
        }}
        back={{ to: lib.buildLink.song(song.id) }}
      >
        <div className={cs("", 'd-flex h-100', 'flex-column', props.className)}>
          
          {/* <div className="d-flex mb-3"> 
            <div>
              {props.children}
            </div>
          </div> */}

          {song &&
            <div className="row flex-fill h-100">

              <div className="col-lg-7 col-xl-6 h-100 pb-3 pt-2">
                <SongChordChartField onBlur={value => setChordChart(value)} initialValue={chordChart} wrapperClassName="h-100" />
              </div>

              <div className="col-lg-5 col-xl-6 h-100 overflow-auto pb-4 pt-2">

                {formHelpers.mutationError && <div className="alert alert-danger" role="alert">{formHelpers.mutationError}</div>}

                <CompactHookFormInput label="Song Title"  error={errors.title}
                  inputProps={{
                    ...register('title', {required: 'Required', maxLength: {value: 200, message: 'Max of 200 characters'}}),
                    className: 'form-control form-control-lg'
                  }}
                />
                <CompactHookFormInput label="Author"  error={errors.author}
                  inputProps={register('author', {maxLength: {value: 200, message: 'Max of 200 characters'}})}
                />
                <div className="row">
                  <div className="col">
                    <CompactHookFormInput label="Key"  error={errors.key}
                      inputProps={register('key', /*{maxLength: {value: 3, message: 'Max of 3 characters'}}*/)}
                    />
                  </div>
                  <div className="col">
                    <CompactHookFormInput label="Time sig." error={errors.timeSig}
                      inputProps={register('timeSig', {maxLength: {value: 10, message: 'Max of 10 characters'}})}
                    />
                  </div>
                  <div className="col">
                    <CompactHookFormInput label="Tempo" error={errors.tempo}
                      inputProps={register('tempo', {pattern: {
                        // TODO: this doesn't work
                        value: /^[0-9]+$/,
                        message: 'Please enter a number',
                      } })}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <CompactHookFormInput label="Year"  error={errors.year}
                      // TODO: fix number
                      inputProps={register('year', {maxLength: {value: 12, message: 'Max of 12 characters'}})}
                    />
                  </div>
                  <div className="col">
                    <CompactHookFormInput label="CCLI Song No."  error={errors.ccli}
                      // TODO: fix number
                      inputProps={register('ccli', {maxLength: {value: 20, message: 'Max of 20 characters'}})}
                    />
                  </div>
                </div>
                <CompactHookFormTextarea label="Notes"  error={errors.notes}
                  inputProps={{
                    ...register('notes', {maxLength: {value: 1000, message: 'Max of 1000 characters'}}),
                    rows: 3
                  }}
                />
                <div className="row">
                  <div className="col">
                    <CompactHookFormSelect label="Feel" error={errors.feel} inputProps={register('feel')}>
                      {props.feelChoices.map(c => <option key={c} value={c}>{c}</option>)}
                    </CompactHookFormSelect>
                  </div>
                  <div className="col">
                    <CompactHookFormInput label="Theme"  error={errors.theme}
                      inputProps={register('theme', {maxLength: {value: 200, message: 'Max of 200 characters'}})}
                    />
                  </div>
                </div>
                <CompactHookFormInput label="Copyright"  error={errors.copyright}
                  inputProps={register('copyright', {maxLength: {value: 200, message: 'Max of 200 characters'}})}
                />
                <CompactHookFormInput label="URL (Eg. YouTube link)"  error={errors.url}
                  inputProps={register('url', {maxLength: {value: 200, message: 'Max of 200 characters'}})}
                />
                {/* <TextField name="tags"   label="Tags"  initialValue={song.tags} onCommit={handleFieldCommit} /> */}
              </div>
            </div>
          }
        </div>
      </FullPage>
    </form>
  );
};