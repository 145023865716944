import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PropsWithChildren } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { LogoutBtn } from '../auth/LogoutBtn';
import { FullPageContentPane } from '../chrome/FullPageContentPane';
import { H1 } from '../chrome/H1';
import { Loading } from '../chrome/Loading';
import { actionButtonClassName } from '../components/ActionButton';
import { LibrariesFragment, MeFragment } from '../generated/graphql';
import { icons } from '../icons';
import { buildLink } from '../routes';
import { LibraryImage } from './LibraryImage';


interface Props {
  me: MeFragment | null
  libraries: readonly LibrariesFragment[] | null
}


export const HomePage = ({me, libraries}: Props) => {
  const history = useHistory();
  return (
    <FullPageContentPane
      title={<H1>{me?.user?.shortName ? `${me?.user.shortName}, s` : 'S'}elect a library</H1>}
      // sidebar={{
      //   title: "Songlib Chord Charts",
      //   children: <div className="p-3">Some app settings, and user info?</div>
      // }}
      actions={
        <>
          <Link to={buildLink.account()} className={actionButtonClassName}>My Account</Link>
          <LogoutBtn className={actionButtonClassName}>Logout</LogoutBtn>
        </>
      }
    >
      {libraries ? 
        <div className="row">
          {libraries.map(o =>

            <ButtonWrap key={o.id}> 
              <div onClick={() => history.push(buildLink.library(o.id))} key={o.id} className={buttonClassName}>
                <LibraryImage library={o}/>
                <div className='media-body'>
                  <h5 className="mt-2">{o.title}</h5>
                  {/* {o.title} */}
                  <p className='small mt-1'>
                    <Link to={buildLink.songs(o.id)}>{o.songCount} song{o.songCount !== 1 && 's'}</Link>
                    {' | '}
                    <Link to={buildLink.setlists(o.id)}>{o.setlistCount} set{o.setlistCount !== 1 && 's'}</Link>
                    {' | '}
                    <Link to={buildLink.settings(o.id)}>{o.memberCount} member{o.memberCount !== 1 && 's'}</Link>
                  </p>
                </div>
              </div>
            </ButtonWrap>

          )}

          <ButtonWrap>
            <button className={buttonClassName}>
              <div className="text-center"><FontAwesomeIcon icon={icons.plus}/></div>
              Add Library
            </button>
          </ButtonWrap>

        </div>
      :
        <Loading/>
      }
    </FullPageContentPane>
  )
};

const buttonClassName = "btn btn-light w-100";

const ButtonWrap = (props: PropsWithChildren<{}>) => (
  <div className="col-12 col-sm-6 col-lg-4 col-xl-3 mb-4">{props.children}</div>
)