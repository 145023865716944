import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import cs from 'classnames'
import React, { ClassAttributes, HTMLAttributes, ReactHTML } from 'react'
import { LibrarySelectFragment, LibraryType } from './generated/graphql'
import { icons } from './icons'
import styles from './LibraryLi.module.scss'


interface Props<T extends HTMLElement> extends HTMLAttributes<T> {
  readonly type: keyof ReactHTML,
  readonly library: LibrarySelectFragment
  readonly selected?: boolean
}
// function createElement<P extends HTMLAttributes<T>, T extends HTMLElement>(
//   type: keyof ReactHTML,
//   props?: ClassAttributes<T> & P | null,
//   ...children: ReactNode[]): DetailedReactHTMLElement<P, T>;

export const LibraryLi = <T extends HTMLButtonElement>({type, library, className, selected, ...rest}: ClassAttributes<T> & Props<T>) => 
  React.createElement(type, {
    ...rest,
    className: cs(className, styles.LibraryLi, selected && 'font-weight-bold'),
    children: <>
      {library.image?.tiny ? 
        <img src={library.image?.tiny.url} alt={`Logo for ${library.title}`}/> 
        : 
        <span className={styles.Img} style={{backgroundColor: library.color}}>
          {library.type === LibraryType.Personal ? <FontAwesomeIcon icon={icons.privateLibrary}/> : library.title.substr(0, 1).toUpperCase()}
        </span>
      }
      {library.title}
    </>
  })
  // <type className={cs(styles.LibraryLi)}>
  //   {library.image?.tiny ? <img src={library.image?.tiny.url}/> : <span className={styles.Img} style={{backgroundColor: library.color}}></span>}
  //   {library.title}
  // </type>
