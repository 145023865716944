import { faBullhorn, faCaretCircleDown, faCaretDown, faClose, faListDropdown, faSidebarFlip } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useElementId } from '../hooks/elementIds';
import { LiveSyncContext, useLiveSyncContext } from './LiveSyncContext';
import cs from 'classnames';
import { Link, useHistory } from 'react-router-dom';
import { buildLink } from '../routes';
import { MutationPushLiveArgs, PushLiveInput, usePushLiveMutation } from '../generated/graphql';
import { LiveSyncSongList } from './LiveSyncSongList';
import { ToggleSwitch } from '../components/ToggleSwitch';


interface Props {
  className?: string
  buildSongLink?: (library: number, setlist: number, setlistsong: number) => string
  setlistKey?: string
  allowPush?: boolean
}

export const LiveSyncDropdown = (props: Props) => {
  const c = useLiveSyncContext();
  const enabledId = useElementId('live-sync')
  const setlist = c.currentSong?.setlist;
  const [, executePushLive] = usePushLiveMutation();
  const history = useHistory();

  const buildPushLink = props.buildSongLink ?? buildLink.setlistsong;

  const songs = (c.currentSong?.setlist.songs ?? setlist?.songs) ?? null;

  return (
    <div style={{width: '320px'}} onClick={e => e.stopPropagation()} className={cs(props.className, 'd-flex flex-column h-100')}>
      <div className='d-flex justify-content-between bg-light ps-3 border-bottom align-items-center'>
        
        <ToggleSwitch value={c.enabled} onChange={c.setEnabled}>
          Live&nbsp;Sync
        </ToggleSwitch>
        <div>
            <button className={`btn btn-default rounded-0 ${cs(c.sidebar && 'text-primary')}`} onClick={() => c.setSidebar(!c.sidebar)}><FontAwesomeIcon icon={faSidebarFlip}/></button>
            {/* <button className='btn btn-default rounded-0'><FontAwesomeIcon icon={faClose}/></button> */}
        </div>

      </div>

      {setlist && (c.active) && 
        <div className='flex-fill'>
          
          <div className='p-3'>
            <b>{setlist?.title}</b>
            <br/>
            <small>{setlist.date} | {setlist.songs.length} Songs</small>
          </div>

          {setlist && songs && c.libraryId &&
            <LiveSyncSongList
              setlist={setlist}
              songs={songs}
              liveId={c.currentSong?.id}
              setlistKey={props.setlistKey}
              showPush={props.allowPush}
              buildSongLink={sss => (props.buildSongLink ?? buildLink.setlistsong)(setlist.libraryId, setlist.id, sss)}
            />
          }
          
          <ul className="list-group list-group-flush border-top border-bottom">

            {setlist.songs.map((s, i) => (
                <Link 
                    to={buildPushLink(c.libraryId as number, setlist.id, s.id)} 
                    key={s.id} 
                    className={cs("d-flex list-group-item list-group-item-action align-items-center  p-0 py-1 ps-3 ", c.currentSong?.song.id === s.song.id && 'fw-bold')}
                > 
                  <div>
                    {i+1}. {s.title} {s.transpose.toKey && `(${s.transpose.toKey})`}
                    
                  </div>

                  {props.allowPush &&
                    <button className='btn ms-auto text-primary border-start rounded-0' onClick={e => {

                      if (c.active && setlist.id) {
                        const vars: MutationPushLiveArgs = {
                          data: {
                            setlistId: setlist.id,
                            songId: s.song.id,
                          },
                          setlistKey: props.setlistKey ?? null,
                        };
                        console.log(vars, props.setlistKey)
                        // if (props.setlistKey) {
                        //   vars.setlistKey = props.setlistKey
                        // }
                        executePushLive(vars);
                        history.push(buildPushLink(c.libraryId ?? 0, setlist.id, s.id));
                      }
                      e.preventDefault()
                    }}>
                      <FontAwesomeIcon icon={faBullhorn}/>
                    </button>
                  }
                </Link>
    
            ))}
          </ul>
        </div>
      }
      
      {c.enabled && <div className='small text-center py-1 bg-success text-white'>You are following when others push live</div>}
      {!c.enabled && <div className='small text-muted bg-light border-top text-center py-1'>You are ignoring when others push live</div>}
      
    </div>
  );
};
