import React from 'react';
import { ModalConfirmButton } from '../chrome/ModalConfirmButton';
import { InviteFragment, useCancelMemberInviteMutation } from '../generated/graphql';
import { prettyAccess } from './access';


interface Props extends Omit<React.HTMLProps<HTMLButtonElement>, 'onClick' | 'children'> {
  invite: InviteFragment
}


export const CancelInviteBtn = (props: Props) => {
  const [result, executeMutation] = useCancelMemberInviteMutation();
  const {invite, ...passThru} = props;
  return (
    <>
      <ModalConfirmButton
        {...passThru}
        modal={{
          name: "CancelInviteConfirm",
          confirmText: result.fetching ? 'Cancelling' : 'Cancel Invite',
          confirmDisabled: result.fetching,
          confirmClassName: '"ms-3 btn btn-danger',
          body: <>
            Are you sure you want to cancel this <b>{prettyAccess(invite.access)}</b> invitation to <b>{invite.toEmail}</b>?
          </>,
          onConfirming: async () => {
            const r = await executeMutation({inviteId: invite.id})
            if (r.data?.cancelMemberInvite.__typename !== 'Invite') {
              return r.data?.cancelMemberInvite.message || 'Error cancelling'
            }
          },
          title: 'Please confirm'
        }}
      >
        Cancel Invite
      </ModalConfirmButton>
    </>
  );
};
