import React from 'react';
import { useMedia } from 'react-media';
import { Fetchable } from '../chrome/Fetchable';
import { FullPageContext } from '../chrome/FullPage';
import { SetlistFragment, useSetlistsQuery } from '../generated/graphql';
import { LibraryContext } from '../library/LibraryContext';
import { GRID_MEDIA_QUERIES } from '../media';
import { BaseProps, Setlists } from './Setlists';
import { SetlistsTable } from './SetlistsTable';

interface Props extends BaseProps {
  pause?: boolean
}
export const SetlistsContainer = (props: Props) => {
  // const p = useRouteParams()
  const lib = React.useContext(LibraryContext)

  const [result] = useSetlistsQuery({
    variables: {libraryId: lib.id},
    requestPolicy: "cache-and-network",
    pause: props.pause,
  });

  const fullPage = React.useContext(FullPageContext)

  const mdGte = useMedia({query: GRID_MEDIA_QUERIES.mdGTE});

  return (
    <Fetchable result={result}>
      {data => 
        mdGte ? 
          <SetlistsTable 
            setlists={(data.setlists?.filter(n => n) as SetlistFragment[]) || []} 
            {...props}
            toSong={fullPage.sidebar.visible}
          />
        : 
          <Setlists 
            setlists={(data.setlists?.filter(n => n) as SetlistFragment[]) || []} 
            {...props}
            toSong={fullPage.sidebar.visible}
          />
      }
    </Fetchable>
  );
};
