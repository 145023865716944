import React from 'react';
import { useHistory } from 'react-router-dom';
import { ModalConfirmButton } from '../chrome/ModalConfirmButton';
import { useDeleteLibraryMutation } from '../generated/graphql';
import { routes } from '../routes';


interface Props extends React.HTMLProps<HTMLButtonElement> {
  libraryId: number
  libraryName: string
}


export const DeleteLibraryBtn = (props: Props) => {
  const [result, executeMutation] = useDeleteLibraryMutation();
  const {onClick, children, libraryId, libraryName, ...passThru} = props;
  const history = useHistory();
  return (
    <>
      <ModalConfirmButton
        {...passThru}
        modal={{
          name: "ConfirmLibraryDelete",
          confirmText: result.fetching ? 'Delete...' : 'Delete Library',
          confirmDisabled: result.fetching,
          confirmClassName: '"ms-3 btn btn-danger',
          body: <>
            Are you sure you want to delete the library
            <span className='fw-bold text-danger' style={{whiteSpace: 'nowrap'}}> {props.libraryName}</span>.
            <br/>
            <br/>
            As this CANNOT BE UNDONE we recommend exporting all the data from your library before deleting.
          </>,
          onConfirming: async () => {
            const r = await executeMutation({libraryId})
            if (r.data?.deleteLibrary.__typename !== 'DeleteLibrarySuccess') {
              return r.data?.deleteLibrary.message || 'Error deleting'
            }
          },
          onConfirmed: () => {
            history.push(routes.home);
          },
          title: 'Please confirm'
        }}
      >
        {children || 'Delete library'}
      </ModalConfirmButton>
    </>
  );
};
