import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cs from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import { SetlistSongFragment, TransposeStyle } from '../generated/graphql';
import { icons } from '../icons';
import { LibraryContext } from '../library/LibraryContext';
import { DisplayKey, KeySelect } from '../transpose/KeySelect';
import { ListInfo } from '../utils/listInfo';


interface Props {
  setlistId: number
  listInfo: ListInfo<SetlistSongFragment>
  setlistSong: SetlistSongFragment
  onKeyChange: (value: DisplayKey) => void
}

export const SetlistSongActions = ({setlistSong, listInfo, ...props}: Props) => {
  const lib = React.useContext(LibraryContext);

  const thisIndex = (() => {
    for (let i=0; i<listInfo.items.length; i++) {
      let _item = listInfo.items[i];
      if (_item?.id === setlistSong.id) {
        return i;
      }
    }
  })();
  if (setlistSong.song)
    return ( 
      <>
        <KeySelect
          originalKey={setlistSong.transpose.fromKey || ''}
          semitones={setlistSong.forMe.lastTranspose ? setlistSong.forMe.lastTranspose.semitones : setlistSong.transpose.semitones || 0}
          style={setlistSong.forMe.lastTranspose ? setlistSong.forMe.lastTranspose.style : setlistSong.transpose.style || TransposeStyle.Sharps}
          align="right"
          btnClass="btn-plain btn"
          onChange={props.onKeyChange}
          setlistTranspose={setlistSong.transpose}
        />
        <div className="btn-group ms-4">
          <Link 
            className={cs("btn btn-plain", (listInfo.previous === null) && 'disabled')}
            to={listInfo.previous ? lib.buildLink.setlistsong(props.setlistId, listInfo.previous.item.id) : '.'}
            title="Previous song in setlist"
          >
            <FontAwesomeIcon icon={icons.prevSong}/>
          </Link>
          <span className="btn btn-plain px-0">
            {(thisIndex || 0) + 1}&nbsp;of&nbsp;{listInfo.count}
          </span>
          <Link 
            className={cs("btn btn-plain", (listInfo.next === null) && 'disabled')}
            to={listInfo.next ? lib.buildLink.setlistsong(props.setlistId, listInfo.next.item.id) : '.'}
            title="Next song in setlist"
          >
            <FontAwesomeIcon icon={icons.nextSong}/>
          </Link>
        </div>
      </>
    )
    return <></>
}