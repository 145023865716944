import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { icons } from '../icons';
import { buildLinkForLibrary, routes } from '../routes';
import cs from 'classnames';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { LibraryContext } from './LibraryContext';


type LibraryTabSlug = 'dashboard' | 'songs' | 'setlists' | 'livesync' | 'presenter' | 'settings';

interface Props {
  id: number
  selected: LibraryTabSlug
}


interface Tab {
  key: LibraryTabSlug
  text: string
  icon: IconDefinition
  active: boolean
  to: string
}


export const getLibraryTabs = (libraryId: number) => {
  const buildLibraryLink = buildLinkForLibrary(libraryId)
  return [
    {
      key: 'dashboard',
      text: 'Dashboard', 
      icon: icons.dashboard, 
      to: buildLibraryLink.library(), 
      // active: !!routeMatches.songs,
    },
    {
      key: 'songs',
      text: 'Songs', 
      icon: icons.songs, 
      to: buildLibraryLink.songs(), 
      // active: !!routeMatches.songs,
    },
    {
      key: 'setlists',
      text: 'Sets', 
      icon: icons.setlist, 
      to: buildLibraryLink.setlists(), 
      // active: !!routeMatches.setlists,
    },
    {
      key: 'livesync',
      text: 'Live Sync', 
      icon: icons.liveSync, 
      to: buildLibraryLink.livesync(), 
      // active: false,
    },
    {
      key: 'presenter',
      text: 'Presenter', 
      icon: icons.presenter, 
      to: buildLibraryLink.presenter(), 
      // active: false,
      // children: <div>Live sync</div>,
      // ref: React.useRef<HTMLDivElement>(null),
    },
    {
      key: 'feed',
      text: 'Activity Feed', 
      icon: icons.feed, 
      to: buildLibraryLink.library(), 
      // active: !!routeMatches.songs,
    },
    {
      key: 'settings',
      text: 'Settings', 
      icon: icons.settings, 
      to: buildLibraryLink.settings(), 
      // active: false,
    },
  ];
}


export const LibrarySidebarContent = (props: Props) => {

  const tabs: Tab[] = getLibraryTabs(props.id).map(t => ({
    ...t, 
    active: t.key === props.selected
  } as Tab));

  return (
    <div>
      <ul className="list-group list-group-flush border-top border-bottom">
        {tabs.map(t => (
          <Link 
            to={t.to} 
            key={t.key} 
            className={cs("list-group-item list-group-item-action", t.active && 'active')}
          >
            {t.text}
          </Link>
        ))}
      </ul>
    </div>
  )
};

type UseLibrarySidebar = [
  {title: string | undefined, children: React.ReactNode},
  {to: string},
]

export const useLibrarySidebar = (options: {selected: LibraryTabSlug}): UseLibrarySidebar => {
  const context = React.useContext(LibraryContext);
  return [
    {
      title: context.library?.title || undefined,
      children: <LibrarySidebarContent {...options} id={context.id} />
    },
    {to: context.buildLink.library()},
  ]
}
