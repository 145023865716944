import { faExternalLink, faGripDotsVertical } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';
import { AccountSettingsCard } from '../account/AccountSettingsCard';
import { SetlistSongFragment } from '../generated/graphql';
import { buildLink } from '../routes';
import cs from 'classnames';
import { extraHostFromUrl } from '../utils/text';


interface Props {
  setlistId: number
  libraryId: number
  setlist_songs: readonly SetlistSongFragment[]
}


export const SetlistSongTable = (props: Props) => {


  

  const [ordering, setOrdering] = React.useState(false)


  return (
    <AccountSettingsCard title="Songs" flush action={
      <>
        <button className='btn btn-link'>Add song</button>
        <button className='btn btn-link' onClick={() => setOrdering(!ordering)}>{ordering ? 'Save song order' : 'Re-order songs'}</button>
      </>
    }>
      <table className="table">
        <thead>
          <tr>
            {ordering && <th></th>}
            <th>No.</th>
            <th>Song title</th>
            <th>Author</th>
            <th>More info</th>
            <th>Key</th>
            <th>Notes</th>
          </tr>
        </thead>
        <tbody>
        {props.setlist_songs.map((s, index) => (
          <tr key={s.id}>
            {ordering && <td className='ps-3'><FontAwesomeIcon icon={faGripDotsVertical}/></td>}
            <td className={cs(!ordering && 'ps-3')}>{index + 1}.</td>
            <td>
              <Link key={s.id} to={buildLink.setlistsong(props.libraryId, props.setlistId, s.id)}>
                {s.title}
              </Link>
            </td>
            <td>{s.song.author}</td>
            <td>{s.song.url && <>
              <a href={s.song.url} target="_blank">{extraHostFromUrl(s.song.url)}</a>&nbsp;<FontAwesomeIcon icon={faExternalLink}/>
              </>
              }
            </td>
            <td className='text-center'>{s.transpose.toKey || (s.transpose.semitones === 0 ? <span title="Original key">Orig.</span> : `${s.transpose.semitones < 0 ? '-' : '+' }${s.transpose.semitones}`)}</td>
            <td>{s.notes || <span className='text-muted'>No notes</span>}</td>
          </tr>
        ))}
        </tbody>
      </table>

    </AccountSettingsCard>
  )

}