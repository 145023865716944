import React from 'react';
import { SetlistSongFragment, TransposeStyle } from '../generated/graphql';
import { LibraryContext } from '../library/LibraryContext';
import { ChordChartAutoSizing } from '../song/ChordChartAutoSizing';
import { DisplayKey } from '../transpose/KeySelect';
import { ListInfo } from '../utils/listInfo';



interface Props {
  setlistId: number
  listInfo: ListInfo<SetlistSongFragment>
  setlistSong: SetlistSongFragment
  onKeyChange: (value: DisplayKey) => void
  onSidebarToggle: () => void
  sizeSource?: any
  sidebarToggle: boolean
  back: boolean
}

export const SetlistSongPane = ({setlistSong, listInfo, ...props}: Props) => {
  const lib = React.useContext(LibraryContext);

  const thisIndex = (() => {
    for (let i=0; i<listInfo.items.length; i++) {
      if (listInfo.items[i]?.id === setlistSong.id) {
        return i;
      }
    }
  })();

  return (
    // <MainContentPane
    //   scrollContent
    //   sidebarVisible={true}
    //   back={{to: lib.buildLink.setlist(props.setlistId)}
    //   }
    //   title={
    //     setlistSong.song &&
    //       <H1>
    //         <span className="me-2">{(thisIndex || 0) + 1}. {setlistSong.title}</span>
    //         {song.author && <small className="lead text-muted">{song.author}</small>}
    //       </H1>
    //   }
      // actions={
      //   setlistSong.song && 
      //     <>
      //       <KeySelect
      //         originalKey={setlistSong.transpose.fromKey || ''}
      //         semitones={setlistSong.transpose.semitones || 0}
      //         style={setlistSong.transpose.style || TransposeStyle.Sharps}
      //         align="right"
      //         btnClass="btn-plain btn"
      //         onChange={props.onKeyChange}
      //       />
      //       <div className="btn-group ms-4">
      //         <Link 
      //           className={cs("btn btn-plain", (listInfo.prevIndex === null) && 'disabled')}
      //           to={listInfo.prevItem ? lib.buildLink.setlistsong(props.setlistId, listInfo.prevItem.id) : '.'}
      //           title="Previous song in setlist"
      //         >
      //           <FontAwesomeIcon icon={icons.prevSong}/>
      //         </Link>
      //         <span className="btn btn-plain px-0">
      //           {(thisIndex || 0) + 1}&nbsp;of&nbsp;{listInfo.count}
      //         </span>
      //         <Link 
      //           className={cs("btn btn-plain", (listInfo.nextIndex === null) && 'disabled')}
      //           to={listInfo.nextItem ? lib.buildLink.setlistsong(props.setlistId, listInfo.nextItem.id) : '.'}
      //           title="Next song in setlist"
      //         >
      //           <FontAwesomeIcon icon={icons.nextSong}/>
      //         </Link>
      //       </div>
      //     </>
      // }
    // >
      setlistSong &&  
        <>  
          {listInfo.selected && <p className="font-italic text-info">{listInfo.selected.item.notes}</p>}  
          <ChordChartAutoSizing
            plainText={setlistSong.song.chordChart || ''}
            transpose={setlistSong.forMe.lastTranspose ? setlistSong.forMe.lastTranspose : {
              semitones: setlistSong.transpose.semitones || 0,
              style:  setlistSong.transpose.style || TransposeStyle.Sharps,
            }}
            sizeSource={props.sizeSource}
          />
        </>
    //   }
    // </MainContentPane>
  )
}