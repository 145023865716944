import { faChartTreeMap, faCloudArrowDown, faCloudArrowUp, faPlus, faRectangleList } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { BooleanParam, StringParam, useQueryParams } from 'use-query-params';
import { AppContext } from '../app/AppContext';
import { FullPage } from '../chrome/FullPage';
import { H1 } from '../chrome/H1';
import { RadioButtons } from '../components/RadioButtons';
import { LibraryContext } from '../library/LibraryContext';
import { useLibrarySidebar } from '../library/LibrarySidebar';
import { buildLink, routes } from '../routes';
import { settings } from '../settings';
import { SongPage } from '../song/SongPage';
import { SongFormPage } from '../songform/SongFormPage';
import { sessionState, useSessionState } from '../storage/sessionState';
import { AddSongButton } from './AddSongButton';
import { SongsByLetter } from './SongsByLetter';
import { SongsByTable } from './SongsByTable';
import { useSongs } from './useSongs';


export const SongsPage = () => {
  
  const [sidebarInfo, back] = useLibrarySidebar({selected: 'songs'})

  const [queryParams, setQueryParams] = useQueryParams({
    search: BooleanParam,
    sidebar: BooleanParam,
    q: StringParam,
    rows: BooleanParam,
  })
  // const displayMode = queryParams.rows ? "rows" : "cols";

  const [layout, setLayout] = useSessionState(sessionState.songListLayout);
  // console.log(displayMode)
  const songs = useSongs({className: "px-3", slim: layout === 'cols'})
  
  const lib = React.useContext(LibraryContext);
  const history = useHistory();

  const app = React.useContext(AppContext)
  return (
    <Switch>

      <Route path={routes.songs} exact>
        {p => (
          <FullPage 
            title={<H1>Songs</H1>}
            sidebar={{...sidebarInfo, back: {to: buildLink.home()}}} 
            back={back}
            // back={{to: buildLibraryLink.library()}}
            actions={
              <div className='d-flex'>

                <input 
                  defaultValue={queryParams.q || ''} 
                  onChange={e => {setQueryParams({q: e.target.value || ''})}}
                  className="form-control form-search-input rounded-pill ps-5 py-1  me-3"
                  placeholder="Filter by title, author, lyric, etc."
                  autoFocus={queryParams.search || false}
                  style={{minWidth: '320px'}}
                />

                <RadioButtons<typeof layout>
                  choices={[
                    {icon: faChartTreeMap, value:"cols", title: "Group songs by letter in columns"},
                    {icon: faRectangleList, value:"rows", title: "Show a table of songs"},
                  ]}
                  value={layout}
                  onChange={v => setLayout(v)}
                />

                <div className='border-start mx-3'></div>

                <div className="btn-group" role="group">

                  {app.library &&
                    <div className="dropdown">
                      <button className="btn btn-light dropdown-toggle px-2 py-1" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <FontAwesomeIcon icon={faCloudArrowDown} className="d-md-none"/>
                        <span className='d-none d-md-inline'> Export</span> 
                      </button>
                      <ul className="dropdown-menu dropdown-menu-end">
                        <li className='fw-bold pb-2 border-bottom px-3 bg-light' style={{whiteSpace: 'nowrap'}}>
                          Export all songs to
                        </li>
                        <li><a className="dropdown-item" target="_blank" href={settings.serverURL + app.library.exportSongsAsSinglePdfLink}>A single PDF</a></li>
                        <li><a className="dropdown-item" target="_blank" href={settings.serverURL + app.library.exportSongsAsZipOfPdfsLink}>Zip of separate PDF's</a></li>
                        {/* <li className='dropdown-divider'></li> */}
                        <li><a className="dropdown-item" target="_blank" href={settings.serverURL + app.library.exportSongsAsOpenLpLink}>Open LP</a></li>
                        <li className='text-center small border-top pt-2 text-muted px-3' style={{whiteSpace: 'nowrap'}}>
                          <small className='fw-normal'>NB. Exports may take a while</small>
                        </li>
                      </ul>
                    </div>
                  }
                  <button className='btn btn-light px-2 py-1'>
                    <FontAwesomeIcon icon={faCloudArrowUp} className="d-md-none"/>
                    <span className='d-none d-md-inline'> Import</span>
                  </button>
                  <AddSongButton libraryId={lib.id} className='btn btn-light px-2 py-1' onSuccess={song => {
                    history.push(lib.buildLink.editsong(song.id));
                  }}>
                    <FontAwesomeIcon icon={faPlus} className="d-md-none"/>
                    <span className='d-none d-md-inline'> New</span>
                  </AddSongButton>
                </div>
                {/* <div className="btn-group me-3">
                  <button type="button" className="btn btn-light px-2 py-1">
                    <FontAwesomeIcon icon={faPlus} className="d-md-none"/>
                    <span className='d-none d-md-inline'> Add</span>
                  </button>
                  <button type="button" className="btn btn-light px-2 py-1 dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-expanded="false">
                    <span className="visually-hidden">Toggle Dropdown</span>
                  </button>
                  <ul className="dropdown-menu">
                    <li><a className="dropdown-item" href="#">Action</a></li>
                    <li><a className="dropdown-item" href="#">Another action</a></li>
                    <li><a className="dropdown-item" href="#">Something else here</a></li>
                    <li><hr className="dropdown-divider"/></li>
                    <li><a className="dropdown-item" href="#">Separated link</a></li>
                  </ul>
                </div> */}
              </div>
            }
            >

              <div className='mt-3'>
                {/* <Toolbar burger main={
                  <NavBarTwoLineBtn 
                    line1={result.data?.library?.title || 'Library Loading...'} 
                    line2="Songs"
                  />}
                  onBurger={() => meContext.setSidebarOpen(true)}
                /> */}
                {/* <h3 className="p-3 mt-navbar">All Songs</h3> */}
                {/* <div className="pt-3 pb-5"></div> */}
                {/* <div>
                  <div className="p-3 mb-3">
                    <div className="row">
                      <div className="col-md-6 offset-md-3">
                        <input 
                          defaultValue={queryParams.q || ''} 
                          onChange={e => {setQueryParams({q: e.target.value || ''})}}
                          className="form-control form-search-input rounded-pill ps-5"
                          placeholder="Start typing to search..."
                          autoFocus={queryParams.search || false}
                        />
                      </div>
                    </div>
                  </div>
                </div> */}
                {songs.data && layout === 'cols' && <SongsByLetter {...songs.data}/>}
                {songs.data && layout === 'rows' && <SongsByTable {...songs.data}/>}
              </div>
            {/* <LibraryTabSongs
              autoFocus={queryParams.search || false}
              onSearchChange={q => {setQueryParams({q})}}
              q={queryParams.q || ''} 
              pause={false}
            /> */}
          </FullPage>
        )}
      </Route>

      <Route path={routes.editSong}>
        {p => (
          <SongFormPage id={parseInt(p.match?.params.song as string)}/>
        )}
      </Route>

      <Route path={routes.song}>
        {p => (
          <SongPage id={parseInt(p.match?.params.song as string)}/>
        )}
      </Route>

    </Switch>
  )
};