import React from 'react';
import { Modal } from '../chrome/Modal';


interface Props {
  id: string;
}


export const SongChordChartHelpModal = (props: Props) => {
  const labelId = `${props.id}Label`
  return (
    <Modal size='lg' scrollable id={props.id} title={'Chord Chart Formatting Guide'}>

      <p>Quick start guide:</p>
      <ul>
          <li>Chords lines start with a <b>period</b></li>
          <li>Lyric lines start with a <b>space</b> <small>(So they line up with the chords)</small></li>
          <li>Headings are wrapped in <b>[square brackets]</b></li>
      </ul>

      <p>Example song:</p>
      <pre className="border p-3 bg-light">{
`[Verse 1]
. G                C         G
Amazing grace how sweet the sound
.     G                   D
That saved a wretch like me

[Verse 2]
.     G          C         G
Twas grace that taught my heart to fear,
.   G                D
And grace my fears relieved;

[Verse 3]
The Lord has promised good to me,
His word my hope secures`}
      </pre>

      <p>Learn more in the knowledge base:</p>
      <ul>
          <li><a target="_blank" href="http://songlib.uservoice.com/knowledgebase/articles/200908-chord-chart-formatting">How to format chord charts</a></li>
          <li><a target="_blank" href="http://songlib.uservoice.com/knowledgebase/articles/204434-copy-paste-a-song-from-the-web">How to copy and paste a song from the web</a></li>
      </ul>

    </Modal>
  );
};