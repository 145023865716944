import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { LibrariesFragment, LibraryType } from '../generated/graphql';
import { icons } from '../icons';


interface Props {
  src: string | null
  bgColor: string
  personal?: boolean
  size?: number
}


export const SquareImage = ({src, bgColor, personal, ...props}: Props) => {

  const size = props.size ?? 56;
  const style = {width: `${size}px`, height: `${size}px`};

  return (
    <div>
      {src ? 
        <img src={src} style={style}/> 
      : 
        <div style={{...style, backgroundColor: bgColor, lineHeight: `${size}px`}} className="mx-auto text-white align-middle">
          {personal && <FontAwesomeIcon icon={icons.user} />}
        </div>
      }
    </div>
  )
};

export const LibraryImage = ({library, size}: {size?: number, library: Pick<LibrariesFragment, 'image' | 'color' | 'type'>}) => (
  <SquareImage 
    src={library.image?.tiny.url || null} 
    bgColor={library.color} 
    personal={library.type === LibraryType.Personal}
    size={size}
  />
)