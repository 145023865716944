import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cs from 'classnames';
import React from "react";
import { Link } from "react-router-dom";
import { TransposeStyle } from "../generated/graphql";
import { icons } from "../icons";
import { useKeySelect } from "./KeySelectHooks";
import { transposeNote } from "./transpose";
// import { preventDefault } from "../utils/events";

// export type KeyStyle = "b" | "#";

export interface DisplayKey {
  semitones: number;
  style: TransposeStyle;
}
interface Props extends DisplayKey {
  originalKey: string;
  onChange?: (value: DisplayKey) => void;
  onSave?: () => void;
  align?: "left" | "right"
  compact?: boolean;
  btnClass?: string;
}

export type KeySelectProps = Props;

export const KeySelect = (props: Props & {setlistTranspose?: DisplayKey}) => {

  const key = useKeySelect(props)

  const r1 = props.setlistTranspose?.semitones || 0;
  // const relSemi = (s: number) => (s - r1) + ((s - r1) <= 0 ? 12 : 0)
  const capo = (s: number) => r1 - s + (s >= 0 ? 12 : 0);

  const choices = props.setlistTranspose ? 
    key.choices().sort((a, b) => capo(a.value) - capo(b.value)) 
  : 
    key.choices();

  return (
    <div className="btn-group">
        <button
          title="Transpose down one semitone"
          onClick={key.handleTransposeDown}
          className={cs(props.btnClass || "btn btn-outline-secondary")}
        >
          <FontAwesomeIcon icon={icons.keyDown}/>
        </button>
        <button
          data-bs-toggle="dropdown"
          title="Select Key"
          style={{ minWidth: "45px"  }}
          className={cs(props.btnClass || "btn btn-outline-secondary")}
        >
          {key.keyDisplay}
          {props.setlistTranspose && key.semitones !== 0 && <span className="ms-1">(capo {capo(props.semitones)})</span>}
        </button>
        <button
          title="Transpose up one semitone"
          onClick={key.handleTransposeUp}
          className={cs(props.btnClass || "btn btn-outline-secondary")}
        >
          <FontAwesomeIcon icon={icons.keyUp}/>
        </button>

      <div className={cs("dropdown-menu", props.align && 'dropdown-menu-right', 'dropdown-menu-iconed')}>
        {choices.map(k => (
          <Link
            key={k.value}
            className={cs('d-flex align-items-center', 'dropdown-item', props.semitones === k.value ? "active" : "", k.value === 0 && 'font-weight-bold')}
            to={`?transpose=${k.value}&style=${props.style}`}
            onClick={e => key.handleTranspose(e, k.value)}
          >
            {key.currentKey ? (
              <>
                <span className="me-auto">
                  {transposeNote(
                    props.originalKey,
                    k.value,
                    props.style === TransposeStyle.Flats ? 'b' : '#'
                  )}{" "}
                </span>
                {props.setlistTranspose?.semitones === k.value && <span className="badge badge-primary mx-3">Setlist Key</span>}
                <small className={cs(props.semitones === k.value ? "" : "text-muted",  k.value === 0 && 'font-weight-bold')}>
                  {props.setlistTranspose ? `capo ${capo(k.value)}` : k.display}
                </small>
              </>
            ) : (
              k.display
            )}
          </Link>
        ))}

        <>
          <div className="dropdown-divider" />
          <Link
            to={`?transpose=${props.semitones}#`}
            onClick={key.handleStyleSharps}
            className={cs('dropdown-item', props.style === TransposeStyle.Sharps ? "active" : "")}
          >
            <span className="dropdown-item-texticon"><b>♯</b></span>
            Show with sharps
          </Link>
          <Link
            to={`?transpose=${props.semitones}b`}
            onClick={key.handleStyleFlats}
            className={cs('dropdown-item', props.style === TransposeStyle.Flats ? "active" : "")}
          >
            <span className="dropdown-item-texticon"><b>&#9837;</b></span>
            Show with flats 
          </Link>
          {key.currentKey && props.semitones !== 0 && props.onSave && (
            <>
              <div className="dropdown-divider" />
              <a className="dropdown-item" href="." onClick={e => {e.preventDefault(); if (props.onSave) props.onSave()}}>
                <FontAwesomeIcon icon={icons.saveKey}/>Save song in this key
              </a>
            </>
          )}
        </>

      </div>
    </div>
  );
}
