import cs from 'classnames';
import React from 'react';
import { FieldError } from 'react-hook-form';
import { useElementId } from '../hooks/elementIds';
import { CompactFormField } from './CompactFormField';


interface Props {
  error?: FieldError
  inputProps: React.HTMLProps<HTMLInputElement>
  label: string
}


export const CompactHookFormInput = (props: Props) => {
  const id = useElementId('HookFormInput');
  const {className = 'form-control', ...passThruProps} = props.inputProps;
  return (
    <CompactFormField id={id} label={props.label} error={props.error?.message}>
      <input 
        id={id} 
        placeholder={props.label}
        className={cs(props.error && 'is-invalid', className)} 
        {...passThruProps}
      />
    </CompactFormField>
  )
};
