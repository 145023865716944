import React from 'react';
import { SetlistMetaFragment } from '../generated/graphql';

interface Props {
  setlist: SetlistMetaFragment
  onDelete?: () => void
  // onCollapse?: () => void
}


export const SetlistMeta = (props: Props) => {
  const { setlist } = props
  return (
    <div className="px-3 pb-3">
      {/* <h4 className="me-auto">{setlist.title}</h4> */}
      <div className="d-flex">
        <p className="lead mb-0">
          {setlist.isDraft && <b>Draft: </b>}
          {setlist.date}
        </p>
        {props.onDelete && 
          <div className="dropdown ms-auto">
            <button className="btn btn-sm btn-outline-secondary" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              ...
            </button>
            <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
              <button className="dropdown-item text-danger" onClick={props.onDelete}>Delete setlist</button>
            </div>
          </div>
        }
      </div>
      
      {setlist.leader?.fullName && <p className="lead mb-0">{`By ${setlist.leader.fullName}`}</p>}
      
      {setlist.notes && <p className="mb-0">{setlist.notes}</p>}
    </div>
  );
};