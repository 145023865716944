import cs from 'classnames'
import React from 'react'
import { Link } from 'react-router-dom'
import { LibrarySelectFragment } from './generated/graphql'
import { buildLink } from './routes'

interface Props {
  options: ReadonlyArray<LibrarySelectFragment>
  value?: number | null
  // onChange: (value: number) => void
  className?: string
}


export const LibrarySelect = (props: Props) => (
  <div className="dropdown">
    <button className={props.className || "btn btn-outline-secondary dropdown-toggle"} type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
    {props.value ? props.options.filter(o => o.id === props.value).map(o => o.title) : 'Select a library'}
    </button>
    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
      {props.options.map(o => 
        <Link to={buildLink.songs(o.id)} 
              key={o.id} 
              className={cs("dropdown-item", o.id === props.value && 'active')} 
              // onClick={() => props.onChange(o.id)}
        >
          {o.title}
        </Link>
      )}
    </div>
  </div>
)