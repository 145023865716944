import React from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { StringParam, useQueryParams } from 'use-query-params';
import { SortDirection, useSongsQuery, SongSortBy, useSongsForTableQuery } from '../generated/graphql';
import { LibraryContext } from '../library/LibraryContext';
import { BaseProps, Props as SongsProps, SongFilter } from './SongsListGroup';




// const songMatchesKeyword = (s: SongsFragment, kw: string) =>
//   iContains(s.title, kw) ||
//   // iContains(s.chordChart, kw) ||
//   iContains(s.author, kw)
//   // iContains(s.notes, kw) ||
//   // iContains(s.copyright, kw) ||
//   // iExact(s.key, kw) ||
//   // iExact(s.year, kw);


// export const filterSongs = (items: ReadonlyArray<SongsFragment>, filter: SongFilter) => 
//   filterByKeywords(filter.q, items, songMatchesKeyword);


// export const FilterCount = (props: { filtered: number; total: number, entityName: string }) =>
// <>
//   {props.total === props.filtered
//     ? <>Showing all <b>{props.total}</b> {props.entityName}{props.total === 1 ? "" : "s"}</>
//     : <>Found <b>{props.filtered}</b> match{props.filtered === 1 ? "" : "es"}</>
//   }
// </>


interface Props extends BaseProps {
  // component: React.ComponentType<SongsProps>
  className?: string
  // children: (props: SongsProps) => React.ReactElement
  pause?: boolean
  selectedSongId?: number
  slim: boolean
}

export const useSongs = (props: Props) => {
  const lib = React.useContext(LibraryContext)

  // Filter State is stored in the URL
  const [filter, setFilter] = useQueryParams({ 
    q: StringParam, 
    sortBy: StringParam, 
    sortDir: StringParam,
  })
  
  // Debounced callback so that we only query every 250ms
  const debouncedSetFilter = useDebouncedCallback((value) => setFilter(value), 200);

  const queryVars = {
    filter: {
      libraryId: lib.id,
      q: filter.q || null,
    },
    sort: filter.sortBy && filter.sortDir 
      ? {by: filter.sortBy as SongSortBy, direction: filter.sortDir as SortDirection}
      : null
  }

  const [slimResult] = useSongsQuery({
    variables: queryVars,
    requestPolicy: "cache-and-network",
    pause: props.pause || !props.slim
  });

  const [fatResult] = useSongsForTableQuery({
    variables: queryVars,
    requestPolicy: "cache-and-network",
    pause: props.pause || props.slim,
  });


  const { fetching, error, data } = props.slim ? slimResult : fatResult;

  if (data){
    // const filteredSongs = filterSongs(data.songs, {q: filter.q || ''})
    return {
      data: {
        songs: data.songs || [],
        selectedId: props.selectedSongId,
        onFilterDebounced: debouncedSetFilter,
        onFilter: (v: SongFilter) => setFilter(v),
        filter: ({
          q: filter.q || '',
          ...(
            filter.sortBy && filter.sortDir ?
            {sortBy: filter.sortBy, sortDir: filter.sortDir}
            : {sortBy: SongSortBy.Title, sortDir: SortDirection.Asc}
          )
        }) as SongFilter,
        filterQuery: filter.q || filter.sortDir || filter.sortBy 
          ? "?" + [filter.q && `q=${encodeURIComponent(filter.q || '')}`, filter.sortBy && `sortBy=${filter.sortBy}`, filter.sortDir && `sortDir=${filter.sortDir}`].filter(x=>x).join('&') : '',
        className: props.className,
      },
      fetching,
      error: null,
    }
  }
  if (fetching) 
    return {fetching: true, error: null, data: null}
  return {fetching: false, error: error?.message, data: null};

};
