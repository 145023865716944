import { faLock } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { LibrariesFragment, LibraryFragment, LibraryType } from '../generated/graphql';

interface Props {
  readonly library: LibrariesFragment | LibraryFragment
  size: number
}


export const LibraryIcon = ({library, size}: Props) => (
  <div style={{width: `${size}px`, background: library.image ? undefined : library.color}} className="me-2 text-white text-center d-inline-block">
    {library.image && <img src={library.image.tiny.url} style={{width: `${size}px`, height: `${size}px`}} className="me-2"/>}
    {!library?.image && (
      library.type === LibraryType.Personal 
        ? <FontAwesomeIcon icon={faLock}/>
        : library.title.substring(0,1).toLocaleUpperCase()
    )}
  </div>
)