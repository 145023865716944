import cs from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import { AppContext } from '../app/AppContext';
import { useDates } from '../dates';
import { SetlistsFragment } from '../generated/graphql';
import { LibraryContext } from '../library/LibraryContext';


export interface BaseProps {
  onAddClick?: () => void
  selectedId?: number
  toSong?: boolean
}

interface Props extends BaseProps {
  readonly setlists: readonly SetlistsFragment[]
}


export const SetlistsTable = (props: Props) => {
  const lib = React.useContext(LibraryContext)
  const app = React.useContext(AppContext)
  const dt = useDates({userFormat: app.me?.preferences.dateFormat})
  return (
    <>
      <div className=" p-3">
        {props.onAddClick && <button className="btn btn-outline-secondary" onClick={() => props.onAddClick ? props.onAddClick() : undefined }>Add Setlist</button>}
      </div>
      <table className='table'>
        <thead>
          <tr>
            <th>Title</th>
            <th>Date</th>
            <th>Leader</th>
            <th>Notes</th>
            <th>Songs</th>
          </tr>
        </thead>
        <tbody>
          {props.setlists.map(setlist => (
            <tr key={setlist.id}>
              <td>
                <Link key={setlist.id} to={lib.buildLink.setlist(setlist.id)}>
                  {setlist.title}
                </Link>
              </td>
              <td>{dt.formatAsUser(setlist.date)}</td>
              <td>{setlist.leader?.fullName || '-'}</td>
              <td style={{maxWidth: '300px'}}>{setlist.notes}</td>
              <td>{setlist.songCount}</td>
            </tr>
            ))
          }
        </tbody>
      </table>
    </>
  )
};
