import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { LibraryFragment, MeFragment } from '../generated/graphql';
import { LiveSyncPage } from '../livesync/LiveSyncPage';
import { PresenterPage } from '../presenter/PresenterPage';
import { buildLinkForLibrary, routes } from '../routes';
import { SetlistsPage } from '../setlists/SetlistsPage';
import { SettingsPage } from '../settings/SettingsPage';
import { SongsPage } from '../songs/SongsPage';
import { LibraryContext } from './LibraryContext';
import { LibraryPage } from './LibraryPage';


interface Props {
  id: number
  library: LibraryFragment | null
  onChange?: () => void
  me?: MeFragment
}


export const Library = (props: Props) => {
  const library = props.library;

  const libraryContextValue = {
    id: props.id, 
    buildLink: buildLinkForLibrary(props.id), 
    readOnly: library?.myAccess !== 'FULL_CONTROL',
    library,
  }

  return (
    <LibraryContext.Provider value={libraryContextValue}>
      <Switch>
        <Route path={routes.library} exact render={p => <LibraryPage id={props.id} library={library} />}/>
        <Route path={routes.songs} render={p => <SongsPage />}/>
        <Route path={routes.setlists} render={p => <SetlistsPage />}/>
        <Route path={routes.livesync} render={p => <LiveSyncPage />}/>
        <Route path={routes.presenter} render={p => <PresenterPage />}/>
        <Route path={routes.settings} render={p => <SettingsPage id={props.id} />}/>
      </Switch>
    </LibraryContext.Provider>
  );
  
};