import { faCopy } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { required, TextField } from '../fields';
import { LibraryAccess, MembershipFragment, InviteFragment, useUpdateMemberAccessMutation } from '../generated/graphql';
import { icons } from '../icons';
import { settings } from '../settings';
import { orderdLibraryAccesses, prettyAccess } from './access';

interface Props {
  member: MembershipFragment
  disabled?: boolean
}


export const MembersAccessDropdown = ({member, disabled}: Props) => {

  const [{fetching}, executeUpdateAccess] = useUpdateMemberAccessMutation();

  const changeValue = async (access: LibraryAccess) => {
    await executeUpdateAccess({membershipId: member.id, access})
  }

  return (
    <div className="dropdown">
      <button className="btn btn-light dropdown-toggle" disabled={disabled || fetching} type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        {prettyAccess(member.access)}
        {/* <FontAwesomeIcon icon={icons.loadingSpinner} spin style={{visibility: mutationState.fetching ? 'visible' : 'hidden'}}/> */}
      </button>
      <div className="dropdown-menu dropdown-menu-right dropdown-menu-iconed" aria-labelledby="dropdownMenuButton">
        {orderdLibraryAccesses.map(a => 
          <button key={a} className="dropdown-item" onClick={() => changeValue(a)}>
            <FontAwesomeIcon icon={member.access === a ? icons.radioChecked : icons.radioUnchecked}/> {prettyAccess(a)}
          </button>
        )}
        {/* <button className="dropdown-item">
        <FontAwesomeIcon icon={member.access === LibraryAccess.FullControl ? icons.radioChecked : icons.radioUnchecked}/> Full-control
        </button>
        <button className="dropdown-item">
        <FontAwesomeIcon icon={member.access === LibraryAccess.NoAccess ? icons.radioChecked : icons.radioUnchecked}/> No-access
        </button> */}
      </div>
    </div>
  )
};
