import { faCopy } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { settings } from '../settings';

interface Props {
  link: string
}


export const CopyInviteLinkBtn = (props: Props) => {
  const [clicked, setClicked] = React.useState(false)
  return (
    <button 
      className="btn btn-sm btn-light ms-3" 
      disabled={clicked}
      onClick={() => {
        window.navigator.clipboard.writeText(`${settings.serverURL}${props.link}`);
        setClicked(true);
        window.setTimeout(() => setClicked(false), 1500);
      }}
    >
      <FontAwesomeIcon icon={faCopy}/> {clicked ? 'Copied!' : 'Copy Invite Link'}
    </button>
  )
};
