import React from 'react';
import { SongFragment } from '../generated/graphql';
import { settings } from '../settings';
import { getSonglibTransposeQuery } from '../transpose/transpose';
import { DisplayKey } from '../transpose/KeySelect';


interface Props {
  song: SongFragment
  transpose?: DisplayKey
}


export const SongMetaTable = ({song, ...props}: Props) => {
  const publicShareLimk = settings.serverURL + song.publicShareLink + getSonglibTransposeQuery(props.transpose)
  return (
    <table className="table">
      <tbody>
        <tr>
          <th>Public share link</th>
          <td>
            {song.publicShareLink ? <a href={publicShareLimk} target="_blank" rel="noreferrer">{publicShareLimk}</a>: 'N/A'}</td>
        </tr>
        {/* <tr>
          <th>Other copies of this song</th>
          <td>
          </td>
        </tr> */}
        <tr>
          <th>Year</th>
          <td>{song.year}</td>
        </tr>
        <tr>
          <th>Original key</th>
          <td>{song.key}</td>
        </tr>
        <tr>
          <th>CCLI</th>
          <td>{song.ccli}</td>
        </tr>
        <tr>
          <th>Copyright details</th>
          <td>{song.copyright}</td>
        </tr>

        <tr>
          <th>Tempo</th>
          <td>{song.tempo}</td>
        </tr>
        <tr>
          <th>Time sig</th>
          <td>{song.timeSig}</td>
        </tr>
        <tr>
          <th>Feel</th>
          <td>{song.feel}</td>
        </tr>
        <tr>
          <th>Theme</th>
          <td>{song.theme}</td>
        </tr>
        <tr>
          <th>URL</th>
          <td>{song.url}</td>
        </tr>
        <tr>
          <th>Notes</th>
          <td>{song.notes}</td>
        </tr>
        <tr>
          <th>Created</th>
          <td>{song.dateCreated}</td>
        </tr>
        <tr>
          <th>Last modified</th>
          <td>{song.dateModified}</td>
        </tr>
      </tbody></table>
  );
};