import React from 'react';
import { FieldValues, DefaultValues, UseFormSetError } from 'react-hook-form';
import { OperationResult } from 'urql';


interface ErrorReponse {
  __typename: string
  message: string
  fieldErrors?: ReadonlyArray<{ 
    readonly __typename?: 'FieldErrorsField', 
    readonly field: string, 
    readonly messages: ReadonlyArray<string> 
  }>
}

interface Options<TFieldValues extends FieldValues> {
  defaultValues: DefaultValues<TFieldValues>
  setError: UseFormSetError<TFieldValues>
}


export const useMutationFormHelpers = <TFieldValues extends FieldValues>(opts: Options<TFieldValues>) => {

  const [mutationError, setMutationError] = React.useState('');

  return {

    mutationError,

    clearMutationError: () => setMutationError(''),

    processMutationError: (operationResult: OperationResult, mutationError: ErrorReponse | undefined) => {
      // Those general GraphQL Errors
      if (operationResult.error) {
        setMutationError('Something went wrong.  Please try again.  Contact support if the issue persists.')
      }
      // Expected Mutation Errors
      else if (mutationError) {
        // All mutation errors will have a general message field
        setMutationError(mutationError.message)
        // And if the error was caused by a field, then store that error against that field
        if (mutationError.fieldErrors) {
          const validFieldNames = Object.keys(opts.defaultValues);
          for (let e of mutationError.fieldErrors) {
            if (validFieldNames.includes(e.field)) {
              // Can do `as any` as we type checked it above
              opts.setError(e.field as any, {type: 'custom', message: e.messages.join(' ')});
            }
          }
        }
      }
      
      
    },

  }
}