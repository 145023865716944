import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cs from 'classnames';
import React from 'react';
import { useMedia } from 'react-media';
import { icons } from '../icons';
import { GRID_MEDIA_QUERIES } from '../media';
import { FullPageContentPane } from './FullPageContentPane';
import { Sidebar, SidebarProps } from './Sidebar';
import { BackInfo } from './SidebarHeader';

interface MainPaneInfo {
  title: React.ReactNode
  actions?: React.ReactNode
  children: React.ReactNode
}

interface Props extends MainPaneInfo {
  sidebar: SidebarProps;
  back?: BackInfo
  prev?: MainPaneInfo
  next?: MainPaneInfo
}


export const FullPage = (props: Props) => {
  const [sidebarVisible, toggleSidebarVisible] = useLocalStorageBooleanState({localStorageKey: 'songlib-sidebar-visible'});

  const lgGte = useMedia({query: GRID_MEDIA_QUERIES.lgGTE});

  const sidebarActuallyVisible = lgGte && sidebarVisible;

  return (
    <div id="main-content-with-sidebar" className="d-flex h-100" style={{flexGrow: 2}}>
      
      <FullPageContext.Provider value={{
        sidebar: {
          visible: sidebarActuallyVisible, 
          available: lgGte,
          toggle: (value?: boolean) => toggleSidebarVisible(value === undefined ? !sidebarVisible : value)
        }
      }}>
        
        {/* SIDEBAR */}
        {lgGte && props.sidebar && 
          <div className={cs("bg-light ", sidebarVisible ? 'd-none d-lg-block' : 'd-none')} style={{width: '280px', minWidth: '280px'}}>
            <Sidebar
              back={props.sidebar.back || props.back}
              {...props.sidebar}
            />
          </div>
        }

        {/* DIVIDER */}
        {lgGte &&
          <div id="sidebar-divider" className={cs(!sidebarVisible ? 'sidebar-expanded' : 'sidebar-collapsed')}>
            <button 
                className="btn btn-light px-2" 
                style={{}} 
                title="Hide/Show Sidebar" 
                onClick={() => toggleSidebarVisible()}
            >
              <FontAwesomeIcon icon={sidebarVisible ? icons.collapseLeft : icons.expandRight}/>
            </button>
          </div>
        }

        {/* MAIN CONTENT */}
        <FullPageContentPane
          // back={props.back}
          back={props.back}
          title={props.title}
          actions={props.actions}
          sidebarVisible={sidebarActuallyVisible}
          scrollContent
        >
          {props.children}
        </FullPageContentPane>
      
      </FullPageContext.Provider>
      
    </div>
  )
};



// Simple hook that wraps React.useState<boolean>() but initialise the value from localstorage & updates localstorage on change.
const useLocalStorageBooleanState = (config: {localStorageKey: string}) => {
  const [state, setState] = React.useState(localStorage.getItem(config.localStorageKey) === 'true');
  const setSidebarVisible = (value?: boolean) => {
    const newValue = value === undefined ? !state : value;
    setState(newValue);
    localStorage.setItem(config.localStorageKey, newValue ? 'true' : 'false');
  }
  return [
    state,
    setSidebarVisible,
  ] as [boolean, (newValue?: boolean) => void]
}


export interface FullPageContextData {
  sidebar: {
    // Is the sidebar actually visisble at the moment
    visible: boolean,
    toggle: (visible?: boolean) => void,
    // Is the sidebar availble to be shown
    // Eg. is the screen size able to show a sidebar
    available: boolean
  }
}


export const FullPageContext = React.createContext<FullPageContextData>({
  sidebar: {
    visible: false,
    toggle: () => {},
    available: false,
  }
});

