import React from 'react';

// Same as django template tag
export const truncateChars = (str: string, length: number, ellipsis='...') => {

  if (str.length <= length) 
    return str;
  
  if (length - ellipsis.length > 0) 
    return `${str.substr(0, length - ellipsis.length)}${ellipsis}`;
  
  return str.substr(0, length);
}


// Same as django template tag
export const truncateWords = (str: string, length: number, ellipsis='...') => {
  const words = str.split(' ').map(w => w.trim()).filter(w => w.length > 0);

  const len = Math.max(0, length);

  if (words.length <= len)
    return str;

  return `${(words.slice(0, len).join(' '))}${ellipsis}`;
}


export const splitLines = (str: string) =>
  str.split(/[\r\n]+/).map(l=>l.trim()).filter(l=>l.length>0);


// Same as django template tag
export const lineBreaksBr = (str: string) => {
  const result: React.ReactNode[] = [];
  for (let line in splitLines(str)) {
    if (result.length !== 0) 
      result.push(React.createElement('br'));
    result.push(line);
  }
  return result;
}


export const iContains = (text: string, searchString: string) =>
  text.toLowerCase().indexOf(searchString.toLowerCase()) >= 0;

  
export const iExact = (a: string, b: string) => a.toLowerCase() === b.toLowerCase();


type KeywordMatchFunc<T> = (item: T, keyword: string) => boolean;


export const filterByKeywords = <T>(query: string, items: ReadonlyArray<T>, keywordMatchFunc: KeywordMatchFunc<T>) => {
  const keywords = query.split(" ")
    .map(kw=>kw.trim())
    .filter(kw=>kw.length > 0);
  const fn = (s: T) => 
    keywords.filter(kw => !keywordMatchFunc(s, kw)).length === 0;
  return items.filter(fn);
};


export const extraHostFromUrl= (url: string) => {
  const { host } = (new URL(url));
  // if (!options?.keepWww && host.startsWith('www.')) {
  //   return host.replace('www.', '');
  // }
  return host.replace(/^www\./g, '');
}
