

interface ListItemInfo<T> {
  index: number
  number: number
  item: T
}

export interface ListInfo<T> {
  count: number
  items: ReadonlyArray<T>
  selected: ListItemInfo<T> | null
  previous: ListItemInfo<T> | null
  next: ListItemInfo<T> | null
}


export const getListInfo = <T>(
  items: ReadonlyArray<T>, 
  isSelected: (item: T) => boolean,
): ListInfo<T> => {
  let selectedIndex : number | null = null;
  for (let i=0; i<items.length; i++) {
    if (isSelected(items[i] as T)) {
      selectedIndex = i;
      break;
    }
  }

  const prevIndex = selectedIndex !== null ? 
      (selectedIndex > 0 ? selectedIndex - 1 : null) 
    : 
      (items.length > 0 ? items.length - 1 : null)

  const nextIndex = selectedIndex !== null ? 
      (selectedIndex < items.length - 1 ? selectedIndex + 1 : null)
    :
      (items.length > 0 ? 0 : null)
  
  return {
    count: items.length,
    items,
    previous: createListItemInfo(items, prevIndex),
    selected: createListItemInfo(items, selectedIndex),
    next: createListItemInfo(items, nextIndex),
  }
};


function createListItemInfo<T>(items: ReadonlyArray<T>, index: number | null): ListItemInfo<T> | null {
  if (index === null) return null;
  if (index < 0 || index > items.length - 1) {
    throw new Error(`List index ${index} out of range in ${items.length} length array.`)
  }
  return {
    index: index,
    number: index + 1,
    item: items[index] as T,
  }
}