import React from 'react';
import cs from 'classnames';


interface Props {
  title: React.ReactNode
  children: React.ReactNode
  onEdit?: () => void
  editText?: React.ReactNode
  autoHeight?: boolean
  footer?: React.ReactNode
  action?: React.ReactNode
  flush?: boolean
}


export const AccountSettingsCard = (props: Props) => (
  <div className={cs("card", !props.autoHeight && "h-100")}>
    <div className='card-header pe-0 py-0 d-flex align-items-center' style={{minHeight: '45px'}}>
      <div>{props.title}</div>
      {props.onEdit && <button className='btn btn-link ms-auto' onClick={props.onEdit}>{props.editText ?? 'Edit'}</button>}
      {props.action && <div className="ms-auto">{props.action}</div>}
    </div>
    <div className={cs("card-body", props.flush && 'p-0')}>
      {props.children}
    </div>
    {props.footer && 
      <div className='card-footer'>
        {props.footer}
      </div>
    }
  </div>
);
