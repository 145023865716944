import { useForm } from 'react-hook-form';
import { CompactHookFormInput } from '../forms/CompactHookFormInput';
import { CompactHookFormTextarea } from '../forms/CompactHookFormTextarea';
import { LibrarySettingsFragment, useUpdateLibraryMutation } from '../generated/graphql';
import { useMutationFormHelpers } from '../songform/hooks';



interface Props {
  library: LibrarySettingsFragment
  onSuccess: () => void
  onCancel: () => void
}


export const LibraryForm = (props: Props) => {

  const defaultValues = {
    title: props.library.title,
    description: props.library.description,
    ccli: props.library.ccli?.toString() || '',
  } 
  const { register, handleSubmit, setError, formState: {errors} } = useForm({ defaultValues });
  const formHelpers = useMutationFormHelpers({defaultValues, setError});
  const [, executeUpdate] = useUpdateLibraryMutation();

  return (
    <form onSubmit={handleSubmit(async data => {

      formHelpers.clearMutationError()
      const r = await executeUpdate({ libraryId: props.library.id, data:{
        ...data,
        ccli: data.ccli ? parseInt(data.ccli) : null,
      } });
      if (r.data?.updateLibrary.__typename === 'Library') {
        props.onSuccess();
      }
      else {
        formHelpers.processMutationError(r, r.data?.updateLibrary)
      }

    })}>
      
      <CompactHookFormInput label="Title"  error={errors.title}
        inputProps={register('title', {required: 'Required'})}
      />

      <CompactHookFormTextarea label="Description"  error={errors.description}
        inputProps={{rows: 5, ...register('description')}}
      />

      <CompactHookFormInput label="CCLI License No."  error={errors.ccli}
        inputProps={register('ccli', {validate: value => (/^\d+$/).test(value) ? true : 'Must be a number'})}
        // inputProps={register('ccli', {valueAsNumber: true, min: 1, max: 9999999999999})}
      />

      {formHelpers.mutationError && <div className="alert alert-danger" role="alert">{formHelpers.mutationError}</div>}

      <div className='d-flex flex-row-reverse mt-4'>

        <button type="submit" className='btn btn-dark'>Save Library Info</button>
        <button className='btn btn-light me-3' onClick={props.onCancel}>Cancel</button>
      </div>

    </form>
  )

};