import cs from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import { groupByLetter } from './utils';
import { LibraryContext } from '../library/LibraryContext';
// import styles from './SongsByLetter.module.scss';
import { Props } from './SongsListGroup';
import { useSongsForTableQuery } from '../generated/graphql';



export const SongsByTable = (props: Props) => {

  const lib = React.useContext(LibraryContext)
  // const [result, reload] = useSongsForTableQuery({
  //   // requestPolicy: "cache-only"
  //   variables: {filter: props.filter}
  // });
  // console.log(result)
  return (
    <table className={cs('table table-hover table-sm', props.className)}>
      <thead>
        <tr>
          {/* <th scope="col">
            <input className="form-check-input" type="checkbox"/>
          </th> */}
          <th scope="col">Name</th>
          <th scope="col">Author</th>
          <th scope="col">Tags</th>
          <th scope="col">Key</th>
          <th scope="col">Year</th>
          <th scope="col">CCLI</th>
          <th scope="col">URL</th>
        </tr>
      </thead>
      <tbody>
        {props.songs.map(song => (
          <tr
            key={song.id}
            // className={cs(styles.letterGroup)}
            // id={grp.letter}
          >
            {/* <td scope="row">
              <input className="form-check-input" type="checkbox"/>
            </td> */}
            <td>
              <Link
                to={lib.buildLink.song(song.id) + '?sidebar=0'}
                title={`${ song.title }${song.author  ? ` by ${ song.author }`: ''}`}
              >
                {song.title || '(no name)'}
              </Link>
            </td>
            <td>{song.author}</td>
            <td>{song.tags?.map(r => 
              <span key={r.id} className={cs("badge me-1", !r.colorAsHex && 'bg-secondary')} style={r.colorAsHex ? {background: r.colorAsHex} : undefined}>{r.name}</span>
            )}</td>
            <td>{song.key}</td>
            <td>{song.year}</td>
            <td>{song.ccli}</td>
            <td>{song.url && <a href={song.url} target="_blank">{(new URL(song.url).host)}</a>}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
