import React from 'react';
import { usePossibleSetlistLeadersQuery } from '../generated/graphql';


interface Props {
  libraryId: number
}


export const LeaderSelectOptions = (props: Props) => {
  const [result, reexecuteQuery] = usePossibleSetlistLeadersQuery({
    variables: props,
    requestPolicy: 'cache-first',
  })
  
  const activeMembers = result.data?.library?.members
    .filter(m => m.access !== 'NO_ACCESS' && m.user.isActive)
    .sort((a, b) => a.user.fullName?.localeCompare(b.user.fullName || '') || 0)
  
  const inActiveMembers = result.data?.library?.members
    .filter(m => m.access === 'NO_ACCESS' || !m.user.isActive)
    .sort((a, b) => a.user.fullName?.localeCompare(b.user.fullName || '') || 0)

  const Options = ({members}: {members: typeof activeMembers | typeof inActiveMembers}) => 
    <>
      {members?.map(m => (
        <option key={m.id} value={m.user.id}>{m.user.fullName || m.user.email || `User ${m.user.id}`}</option>
      ))}
    </>

  return (
    <>
      <option>-- No leader --</option>
      {(inActiveMembers?.length || 0) > 0 ? 
        <>
          <optgroup label="Active Members"><Options members={activeMembers}/></optgroup>
          <optgroup label="In-Active Members"><Options members={inActiveMembers}/></optgroup>
        </>
      :
        <Options members={activeMembers}/>
      }
    </>
  );
};