import dates from '../dates';
import { FirstDayOfWeek, SetlistFragment, useUpdateSetlistMutation } from '../generated/graphql';
import { useSetlistForm } from './useSetlistForm';


interface Props {
  setlist: SetlistFragment
  className?: string
  onSuccess?: () => void
  userDateFormat: string
  firstDayOfWeek: FirstDayOfWeek
}


export const SetlistForm = ({setlist, ...props}: Props) => {
  const defaultValues = {
    title: setlist?.title || '',
    date: setlist?.date ? dates.formatAsUser(setlist.date, props.userDateFormat) : '',
    notes: setlist?.notes || '',
    leader: setlist.leader?.id.toString() || '',
  }
  const [, executeUpdate] = useUpdateSetlistMutation();
  const { handleSubmit, formHelpers, ...form } = useSetlistForm({ 
    defaultValues: defaultValues,
    userDateFormat: props.userDateFormat,
    firstDayOfWeek: props.firstDayOfWeek,
  });
  return (
    <form className="h-100" onSubmit={handleSubmit(async data => {

      formHelpers.clearMutationError();
      const r = await executeUpdate({setlistId: setlist.id, data: form.prepareSubmitData(data) });
      if (r.data?.updateSetlist.__typename === 'Setlist') {
        if (props.onSuccess) 
          props.onSuccess();
      }
      else {
        formHelpers.processMutationError(r, r.data?.updateSetlist)
      }

    })}>

      <button type='submit' className='btn btn-dark'>Save Setlist</button>
      <br/>

      {formHelpers.mutationError && <div className="alert alert-danger" role="alert">{formHelpers.mutationError}</div>}

      <form.formFields/>

    </form>
  );
};