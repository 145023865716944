import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cs from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import { SetlistSongFragment } from '../generated/graphql';
import { icons } from '../icons';
import { LibraryContext } from '../library/LibraryContext';
import { ifSetWith } from '../utils/render';

interface Props {
  songs: ReadonlyArray<SetlistSongFragment>
  setlistId: number
  selectedId: number
  add?: boolean
}


export const SetlistSongs = (props: Props) => {
  const lib = React.useContext(LibraryContext)
  return (
    <div className="list-group list-group-flush border-top border-bottom">

      {/* Details song button */}
      <Link 
        to={lib.buildLink.setlist(props.setlistId)} 
        className={cs('list-group-item list-group-item-action d-flex', !props.selectedId && 'active')}
      >
        <div className="me-auto">Details</div>
      </Link>

      {/* Songs in the set */}
      {props.songs.map((item, index) => 
        <Link 
          to={lib.buildLink.setlistsong(props.setlistId, item.id)} 
          key={item.id} 
          className={cs('list-group-item list-group-item-action py-1', item.id === props.selectedId && 'active')}
        >
          <div className=" d-flex">
            <div className="pe-2 py-2">{index + 1}.</div>
            <div className="py-2 me-auto">{item.song && item.song.title}</div>
            <div className="ps-3 d-flex flex-column text-right justify-content-center">
              <span className=''>{item.transpose.toKey || (item.transpose.semitones === 0 ? '' : `${item.transpose.semitones}`)}</span>
              {ifSetWith(item.forMe.lastTranspose, t => (
                t.semitones !== 0 &&
                  <b className="small" title="You have transposed this song just for you.  Use a Capo or similar transpose function on your instrument to match the rest of the band.">
                    {t.toKey}&nbsp;
                    {/* <FontAwesomeIcon icon={icons.user}/>&nbsp; */}
                    {/* {t.semitones >= 0 ? 'Capo -' : 'Capo '}
                    {Math.abs(t.semitones)}: {t.toKey} */}
                    (capo {0 - t.semitones})
                    {/* <FontAwesomeIcon icon={icons.user}/> */}
                    {/* &nbsp;=&gt;  */}
                    {/* {t.toKey} */}
                  </b>
              ))}
            </div>
          </div>
          {/* {item.song.author && <div className="small font-italic">{item.song.author}</div>} */}
          {item.notes && <div className="small font-italicX">{item.notes}</div>}
        </Link>
      )}
      
      {/* Add song button */}
      {props.add &&
        <Link 
          to={lib.buildLink.setlistAddSong(props.setlistId)} 
          className={cs('list-group-item list-group-item-action d-flex font-weight-bold', !props.selectedId && 'active')}
        >
          <div className="me-auto">+ Add Song</div>
        </Link>
      }
    </div>
  );
};