import { useForm } from 'react-hook-form';
import { CompactHookFormInput } from '../forms/CompactHookFormInput';
import { useLoginMutation } from '../generated/graphql';
import { useMutationFormHelpers } from '../songform/hooks';


interface Props {
  onSuccess: () => void
}

export const LoginForm = (props: Props) => {
  const [, executeLogin] = useLoginMutation();
  const defaultValues = {email: '', password: ''};
  const { register, handleSubmit, setError, formState: {errors} } = useForm({ defaultValues });
  const formHelpers = useMutationFormHelpers({defaultValues: defaultValues, setError});

  return (
    <form className='bg-light p-3 border' onSubmit={handleSubmit(async data => {
          
        formHelpers.clearMutationError()
        const r = await executeLogin({data});
        if (r.data?.login.__typename === "Me") {
          props.onSuccess();
        }
        else {
          formHelpers.processMutationError(r, r.data?.login)
        }

    })}>

      <CompactHookFormInput label="Email address"  error={errors.email}
        inputProps={register('email', {required: 'Required'})}
      />
      <CompactHookFormInput label="Password"  error={errors.password}
        inputProps={{...register('password', {required: 'Required'}), type: 'password'}}
      />
      {formHelpers.mutationError && <div className="alert alert-danger" role="alert">{formHelpers.mutationError}</div>}
      <div>
        <input type="submit" value="Login" className='btn btn-secondary'/>
      </div>

    </form>
  )
}