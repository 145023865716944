import cs from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import { SongForTableFragment, SongsFragment, SongSortBy, SortDirection } from '../generated/graphql';
import { LibraryContext } from '../library/LibraryContext';


export interface BaseProps {
  onSelect?: (id: number) => void
  title?: React.ReactNode | null
  selectedId?: number
}

export interface Props extends BaseProps {
  readonly songs: readonly SongForTableFragment[]
  className?: string
  onFilter?: (flt: SongFilter) => void
  onFilterDebounced?: (flt: SongFilter) => void
  filter?: SongFilter
  filterQuery: string
  sidebar?: boolean
}

export interface SongFilter {
  q?: string | null
  sortBy?: SongSortBy | null
  sortDir?: SortDirection | null
}

export const SongsListGroup = (props: Props) => {

  const lib = React.useContext(LibraryContext)
  const selectedLi = React.useRef<HTMLAnchorElement>(null);

  React.useEffect(() => {
    if (selectedLi) {
      selectedLi.current?.scrollIntoView({block: 'center'});
    }
  }, [])

  return (
    <div className={cs(`list-group list-group-flush`, props.className)}>
      {props.songs.map(song => (
        <Link 
          key={song.id} 
          to={lib.buildLink.song(song.id) + props.filterQuery + (props.sidebar ? (props.filterQuery ? '&' : '?') + 'sidebar=1' : '')} 
          onClick={(e) => {
            if (props.onSelect) {
              e.preventDefault()
              props.onSelect(song.id)
            }
          }}
          className={cs("list-group-item list-group-item-action py-1 px-3 small", song.id === props.selectedId && 'active')}
          ref={song.id === props.selectedId ? selectedLi : undefined}
        >
          {song.title}
        </Link>
      ))}
    </div>
  );
};
